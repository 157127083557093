import { Component } from '@angular/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent {
	/**
	 * Constructor
	 */
	constructor() {
		// environment.api = `http://${window.location.hostname}:5000/v1`;
	}
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Message } from 'app/layout/common/messages/messages.types';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, ReplaySubject, map, tap } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class MessagesService {
	static getNews() {
		throw new Error('Method not implemented.');
	}
	private _messages: ReplaySubject<Message[]> = new ReplaySubject<Message[]>(1);

	_emitNewMsg: BehaviorSubject<boolean | null> = new BehaviorSubject(null);

	constructor(private _httpClient: HttpClient) {}

	get messages$(): Observable<any> {
		return this._messages.asObservable();
	}

	get emitNewMsg$(): Observable<boolean> {
		return this._emitNewMsg.asObservable();
	}

	set emitNewMsg(value: boolean) {
		this._emitNewMsg.next(value);
	}

	getAll(): Observable<Message[]> {
		return this._httpClient.get<Message[]>(`${environment.api}/cstools/informacao-geral/`).pipe(
			tap(messages => {
				map((isUpdated: boolean) => {
					this._messages.next(messages);

					return isUpdated;
				});
			}),
		);
	}

	getNews(): Observable<any> {
		return this._httpClient.get<any>(`${environment.api}/cstools/informacao-geral/alerta`).pipe(
			tap(messages => {
				map((isUpdated: boolean) => {
					this._messages.next(messages);

					return isUpdated;
				});
			}),
		);
	}

	markAllAsRead(): Observable<any> {
		return this._httpClient.post(`${environment.api}/cstools/informacao-geral/lido`, {}).pipe(
			tap(messages => {
				map((isUpdated: boolean) => {
					this._messages.next(messages);
					return isUpdated;
				});
			}),
		);
	}
}

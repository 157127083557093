<!-- Messages toggle -->
<button mat-icon-button (click)="openPanel()" #messagesOrigin>
	<ng-container *ngIf="unreadCount > 0">
		<span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
			<span
				class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-indigo-600 text-indigo-50 text-xs font-medium"
			>
				{{ unreadCount }}
			</span>
		</span>
	</ng-container>
	<mat-icon [svgIcon]="'heroicons_outline:information-circle'"></mat-icon>
</button>

<!-- Messages panel -->
<ng-template #messagesPanel>
	<div
		class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg"
	>
		<!-- Header -->
		<div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
			<div class="sm:hidden -ml-1 mr-3">
				<button mat-icon-button (click)="closePanel()">
					<mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:x'"></mat-icon>
				</button>
			</div>
			<div class="text-lg font-medium leading-10">Informações Gerais</div>
			<div class="ml-auto">
				<button mat-icon-button [disabled]="unreadCount === 0">
					<mat-icon
						class="icon-size-5 text-current"
						[svgIcon]="'heroicons_solid:information-circle'"
					></mat-icon>
				</button>
			</div>
		</div>

		<!-- Content -->
		<div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">
			<!-- Messages -->
			<ng-container *ngFor="let message of messages; trackBy: trackByFn">
				<div
					class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5"
					[ngClass]="{ unread: !message.read }"
				>
					<!-- Message with a link -->
					<ng-container *ngIf="message.link">
						<!-- Normal links -->
						<ng-container *ngIf="!message.useRouter">
							<a class="flex flex-auto py-5 pl-6 cursor-pointer" [href]="message.link">
								<ng-container *ngTemplateOutlet="messageContent"></ng-container>
							</a>
						</ng-container>
						<!-- Router links -->
						<ng-container *ngIf="message.useRouter">
							<a class="flex flex-auto py-5 pl-6 cursor-pointer" [routerLink]="message.link">
								<ng-container *ngTemplateOutlet="messageContent"></ng-container>
							</a>
						</ng-container>
					</ng-container>

					<!-- Message without a link -->
					<ng-container *ngIf="!message.link">
						<div class="flex flex-auto py-5 pl-6">
							<ng-container *ngTemplateOutlet="messageContent"></ng-container>
						</div>
					</ng-container>
				</div>

				<!-- Message content template -->
				<ng-template #messageContent>
					<!-- Title, description & time -->
					<div class="flex flex-col flex-auto">
						<ng-container *ngIf="message.usuario.nome">
							<div class="font-semibold line-clamp-1" [innerHTML]="message.usuario.nome"></div>
						</ng-container>
						<ng-container *ngIf="message.notas">
							<div class="line-clamp-2" [innerHTML]="message.notas"></div>
						</ng-container>
						<ng-container *ngIf="message.instante">
							<div class="line-clamp-3">
								{{ message.instante | FormatDate }}
							</div>
						</ng-container>
					</div>
				</ng-template>
			</ng-container>

			<!-- No messages -->
			<ng-container *ngIf="!messages || !messages.length">
				<div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
					<div
						class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100 dark:bg-primary-600"
					>
						<mat-icon
							class="text-primary-700 dark:text-primary-50"
							[svgIcon]="'heroicons_outline:information-circle'"
						></mat-icon>
					</div>
					<div class="mt-5 text-2xl font-semibold tracking-tight">Sem novas informações</div>
					<div class="w-full max-w-60 mt-1 text-md text-center text-secondary">
						Quando houver novas Informações elas apareceram aqui.
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</ng-template>

import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable()
export class HeaderSelectCustomerForm {
	constructor(private formBuilder: FormBuilder) {}

	public group(value: any): FormGroup {
		return this.formBuilder.group(value);
	}

	public createForm(): FormGroup {
		return this.formBuilder.group({
			filialId: this.formBuilder.control(null),
			name: this.formBuilder.control(null),
			next: this.formBuilder.control(null),
			nextLast: this.formBuilder.control(null),
			previous: this.formBuilder.control(null),
			previousFirst: this.formBuilder.control(null),
		});
	}
}

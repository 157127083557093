import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IHttpGateway } from '../gateway/HttpGateway.interface';

@Injectable()
export default class HttpClientAdapter implements IHttpGateway {

    constructor(private http: HttpClient) {}

    get<T>(path: string): Observable<T> {
        return this.http.get<T>(path);
    }

    post<T>(path: string, data: any): Observable<T> {
        return this.http.post<T>(path, data);
    }

    put<T>(path: string, data: any): Observable<T> {
        return this.http.put<T>(path, data);
    }

    patch<T>(path: string, data: any): Observable<T> {
        return this.http.patch<T>(path, data);
    }

    delete<T>(path: string): Observable<T> {
        return this.http.delete<T>(path);
    }
}

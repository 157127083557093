import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AlertService } from '../../../services/alert.service';

const API_FILE = `${environment.api}/geral/cadastro/cliente-contrato`;

@Injectable({
	providedIn: 'root',
})
export class FileUploadService {
	constructor(
		private http: HttpClient,
		private alert: AlertService,
	) {}

	uploadFile(data, idContract, filialId): Observable<any> {
		const formData = new FormData();
		data.forEach((file: File) => {
			formData.append('anexos', file, file.name);
		});
		return this.http.post(`${API_FILE}/${idContract}/arquivo?filial_id=${filialId}`, formData).pipe(
			take(1),
			tap(() => this.alert.showSuccess('Arquivos adicionados com sucesso')),
			catchError((erro: any) => {
				this.alert.showError('Erro ao adicionar arquivos', erro?.error?.message);
				return of(erro);
			}),
		);
	}
}

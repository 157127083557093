import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPaginatedResponseModel } from '@botools/shared';
import { BehaviorSubject, Observable, Subject, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { FilialModel } from '../models/filial-model';

const API_FILIAL_ALL = `${environment.api}/unidade`;

@Injectable({
	providedIn: 'root',
})
export class HeaderSelectCustomerService {
	_filialSelected: BehaviorSubject<FilialModel | null> = new BehaviorSubject(null);
	_branchSubject: Subject<FilialModel[]> = new Subject();

	constructor(private http: HttpClient) {}

	get filial$(): Observable<FilialModel> {
		return this._filialSelected.asObservable();
	}

	get branches$(): Observable<FilialModel[]> {
		return this._branchSubject.asObservable();
	}

	set filialSelected(value: any) {
		this._filialSelected.next(value);
	}

	getAllCustomer(): Observable<ApiPaginatedResponseModel<FilialModel>> {
		const instaladoNuvem = !(environment.local == true) ? '&filter.instalado_nuvem=true' : '';
		return this.http
			.get<ApiPaginatedResponseModel<FilialModel>>(
				`${API_FILIAL_ALL}?filter.arquivado=$eq:false${instaladoNuvem}&limit=1000`,
			)
			.pipe(
				tap(branches => {
					if (branches?.data?.length > 0) {
						this._branchSubject.next(branches.data);
					}
				}),
			);
	}

	refresh(): void {
		this.getAllCustomer().pipe(take(1)).subscribe();
	}
}

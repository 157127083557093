import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from './user.types';

const API_USER = `${environment.api}/usuario`;

@Injectable({
	providedIn: 'root',
})
export class UserService {
	private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

	/**
	 * Constructor
	 */
	constructor(private _httpClient: HttpClient) {}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Setter & getter for user
	 *
	 * @param value
	 */
	set user(value: User) {
		// Store the value
		this._user.next(value);
	}

	// eslint-disable-next-line @typescript-eslint/member-ordering
	get user$(): Observable<User> {
		return this._user.asObservable();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Get the current logged in user data
	 */
	get(): Observable<User> {
		return this._httpClient.get<User>('api/common/user').pipe(
			tap(user => {
				this._user.next(user);
			}),
		);
	}

	getUser(id: string): Observable<User> {
		return this._httpClient.get<User>(`${API_USER}/${id}`).pipe(
			tap(user => {
				this._user.next(user);
			}),
		);
	}

	/**
	 * Update the user
	 *
	 * @param user
	 */
	update(user: User): Observable<any> {
		return this._httpClient.patch<User>('api/common/user', { user }).pipe(
			map(response => {
				this._user.next(response);
			}),
		);
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { DateParserHelper } from '../helpers/data-parser.helper';

@Pipe({
	name: 'FormatDate',
})
export class FormatDatePipe implements PipeTransform {
	transform(value: string): string {
		return DateParserHelper.parseToStr(value);
	}
}

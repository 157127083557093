export class ApiPagination {
	sort: string;
	direction: string;
	search: string;
	filter: string;
	limit: number;
	page: number;
	length: number;
	archived: boolean;

	constructor(partial?: Partial<ApiPagination>) {
		this.page = 0;
		this.limit = 10;
		this.archived = false;
		Object.assign(this, partial);
	}
}

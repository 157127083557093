import { Injectable } from '@angular/core';
import { HttpBaseService } from 'app/core/base/services/http-base.service';

@Injectable()
export default class FalhasAcionamentoService extends HttpBaseService {
	constructor() {
		super({
			url: 'cstools/analisador/resumo-unidade/falhas-acionamento',
		});
	}
}

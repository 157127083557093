<div [formGroup]="formGroup">
	<mat-form-field [floatLabel]="'always'" class="flex-auto w-full gt-xs:pr-3">
		<mat-label *ngIf="label">{{ label }}</mat-label>
		<mat-select [formControlName]="controlName" placeholder="Pesquisar..." #singleSelectData>
			<mat-option>
				<ngx-mat-select-search
					[formControl]="dataServerSideFilteringCtrl"
					[searching]="searching"
					[clearSearchInput]="false"
					[preventHomeEndKeyPropagation]="true"
					placeholderLabel="Procurar..."
					noEntriesFoundLabel="Registro não encontrado"
				></ngx-mat-select-search>
			</mat-option>

			<mat-option *ngIf="showNone" class="mt-2 border-b" [value]="valueNone">
				<div class="flex flex-col sm:flex-row sm:items-center py-6">
					<div class="flex items-center">
						<div
							*ngIf="!valueNone"
							class="flex flex-0 items-center justify-center w-8 h-8 rounded-full overflow-hidden"
						>
							<ng-container>
								<div
									class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200"
								>
									<mat-icon
										class="ml-4 icon-size-4 text-hint cursor-pointer"
										[svgIcon]="'heroicons_outline:ban'"
									></mat-icon>
								</div>
							</ng-container>
						</div>
						<div [ngClass]="{ 'ml-2': !valueNone, 'ml-4': valueNone }">
							<div class="font-small">{{ valueNone ? transformData(valueNone) : 'Nenhum' }}</div>
						</div>
					</div>
				</div>
			</mat-option>

			<mat-option class="border-b" *ngFor="let data of filteredServerSideData | async" [value]="data">
				<div class="flex flex-col sm:flex-row sm:items-center">
					<div class="flex items-center">
						<div class="ml-4">
							<div class="font-medium">{{ transformData(data) }}</div>
						</div>
					</div>
				</div>
			</mat-option>
		</mat-select>
	</mat-form-field>
</div>

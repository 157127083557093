import { Pipe, PipeTransform } from '@angular/core';
import { DateParserHelper } from '../helpers/data-parser.helper';

@Pipe({
	name: 'FormatDateHour',
})
export class FormatDateHourPipe implements PipeTransform {
	transform(value: any): any {
		return DateParserHelper.parseDateTimeToStr(value);
	}
}

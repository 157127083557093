import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FuseAlertType } from '../../../../src/@fuse/components/alert';
import { ToastrService } from 'ngx-toastr';

export class AlertType {
	type: FuseAlertType = 'success';
	title: string;
	time: number = 3000;
	message?: string;
	show?: boolean = false;

	constructor(partial?: Partial<AlertType>) {
		Object.assign(this, partial);
	}
}

@Injectable({
	providedIn: 'root',
})
export class AlertService {

  private _toastr = inject(ToastrService);

	private alert: BehaviorSubject<AlertType | null> = new BehaviorSubject(null);

	constructor() {}

	get alert$(): Observable<AlertType> {
		return this.alert.asObservable();
	}

	showSuccess(title: string): void {
		this.show(
			new AlertType({
				title,
			}),
		);
	}

	showError(title: string, message: string): void {
		this.show(
			new AlertType({
				type: 'error',
				time: 5000,
				title,
				message,
			}),
		);
	}

	showInfo(title: string): void {
		this.show(
			new AlertType({
				type: 'info',
				time: 3000,
				title,
			}),
		);
	}

	private show(alertType: AlertType): void {
		const toastrOptions = {
			closeButton: true,
      timeOut: alertType.time
		};
    switch (alertType.type) {
      case 'warn': {
        this._toastr.warning(alertType.message, alertType.title, toastrOptions);
        break;
      }
      case 'success': {
        this._toastr.success(alertType.message, alertType.title, toastrOptions);
        break;
      } 
      case 'warning': {
        this._toastr.warning(alertType.message, alertType.title, toastrOptions);
        break;
      }
      case 'error': {
        this._toastr.error(alertType.message, alertType.title, toastrOptions);
        break;
      }
      default: {
        this._toastr.show(alertType.message, alertType.title, toastrOptions);
        break;
      }
    }
	}
}

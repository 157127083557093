<span
	(click)="unitResumeDrawer.open(); getUnitSummary()"
	class="underline cursor-pointer hover:text-[#019DE8] relative flex flex-0 items-center"
>
	<mat-icon
		class="icon-size-7 myicon"
		[svgIcon]="'icon-silos-bold'"
		matTooltip="{{ 'MODULES.ADMIN.CHECK_IN_OR_CHECK_OUT.OPEN_UNIT_RESUME' | transloco }}"
	></mat-icon>
</span>

<fuse-drawer
	class="w-screen min-w-screen sm:w-80 sm:min-w-80 z-999"
	fixed
	[mode]="'over'"
	[name]="'unitresume'"
	[position]="'right'"
	#unitResumeDrawer
>
	<div class="flex flex-col w-80 ml-2 text-[14px]">
		<span class="text-sky-500 mt-10 text-center"> Resumo da Unidade </span>

		<span class="text-sky-500 mt-8 bg-slate-100">
			<mat-icon class="icon-size-4 myicon text-sky-500 ml-2" [svgIcon]="'icon-silos-blue'"></mat-icon>
			Movimentação de Nível (24hs)</span
		>
		<div class="items-left flex flex-col">
			@for (movimento of movimentoEstoque; track $index) {
				@if (movimento) {
					<span
						[ngClass]="{
							'bg-gray-100': $index % 2 === 1,
							'mt-2': true,
						}"
						>{{ movimento.estrutura_armazenagem.nome }}</span
					>
				}
			}
		</div>
		<span class="text-sky-500 mt-8 bg-slate-100">
			<mat-icon class="icon-size-4 myicon ml-2" [svgIcon]="'icon-alerts-blue'"></mat-icon>
			Falha de Acionamento (24hs)</span
		>
		<div class="items-left flex flex-col">
			@for (falha of falhasAcionamento; track $index) {
				@if (falha) {
					<span
						[ngClass]="{
							'bg-gray-100': $index % 2 === 1,
							'mt-2': true,
						}"
						>{{ falha.estrutura_armazenagem.nome }}</span
					>
				}
			}
		</div>
		<span class="text-sky-500 mt-8 bg-slate-100">
			<mat-icon class="icon-size-4 myicon ml-2" [svgIcon]="'icon-heating-variables-blue'"></mat-icon>
			Focos de Calor</span
		>
		<div class="items-left flex flex-col">
			@for (resumo of resumoUnidade?.foco_calor; track $index) {
				@if (resumo) {
					<span
						[ngClass]="{
							'bg-gray-100': $index % 2 === 1,
							'mt-2': true,
						}"
						>{{ resumo.estrutura_armazenagem.nome }}</span
					>
				}
			}
		</div>
		<span class="text-sky-500 mt-8 bg-slate-100">
			<mat-icon class="icon-size-4 myicon text-sky-500 ml-2" [svgIcon]="'iconsmind:chemical-blue'"></mat-icon>
			Amostras em Atraso</span
		>
		<div class="items-left flex flex-col">
			@for (resumo of resumoUnidade?.amostragem_atraso; track $index) {
				@if (resumo) {
					<span
						[ngClass]="{
							'bg-gray-100': $index % 2 === 1,
							'mt-2': true,
						}"
						>{{ resumo.estrutura_armazenagem.nome }}</span
					>
				}
			}
		</div>
	</div>
</fuse-drawer>

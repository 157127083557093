<div class="flex justify-between items-center">
	<div class="flex items-center">
		<span class="text-[#535767] opacity-70 text-2xl font-semibold"> Reiniciar Check-in </span>
	</div>
	<div class="flex">
		<button (click)="close()">
			<mat-icon class="icon-size-4">close</mat-icon>
		</button>
	</div>
</div>
<div mat-dialog-content class="text-secondary text-lg my-4">Explicar o motivo</div>
<textarea
	class="rounded p-3 w-full border-[0.4px] border-[#5357674d] resize-none min-h-36"
	[(ngModel)]="reason"
></textarea>
<div class="flex justify-between mt-4">
	<button
		(click)="close()"
		class="flex items-center justify-center rounded-[6px] w-[170px] sm:w-[116px] h-[41px] border-2 border-[#535767] text-[#535767] opacity-70 text-lg font-semibold"
	>
		{{ 'ACTIONS.CANCEL' | transloco }}
	</button>
	<button
		(click)="save()"
		class="flex items-center justify-center rounded-[6px] w-[170px] sm:w-[122px] h-[41px] border-2 bg-[#019DE8] border-[#019DE8] text-white text-lg font-semibold"
	>
		{{ 'ACTIONS.CONFIRM' | transloco }}
	</button>
</div>

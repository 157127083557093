export class StringFormatHelper {
	public static formatPhone(phone: string): string {
		return phone.replace(/(\d{2})?(\d{2})?(\d{4,5})(\d{4})$/, '$1 $2 $3-$4').replace(/[ ]{2,}/, '');
	}

	public static formatCpf(cpf: string): string {
		return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
	}

	public static formatCep(cep: string): string {
		return cep.replace(/(\d{5,})(\d{3})$/, '$1-$2');
	}

	public static cleanCharacterAndLettersFromNumber(numbers: string): string {
		return numbers?.replace(/[^0-9]/g, '');
	}

	public static formatFileSize(size: number): string {
		if (size < 1024) {
			return size + ' B';
		} else if (size < 1024 * 1024) {
			const kbSize = (size / 1024).toFixed(2);
			return kbSize + ' KB';
		} else if (size < 1024 * 1024 * 1024) {
			const mbSize = (size / (1024 * 1024)).toFixed(2);
			return mbSize + ' MB';
		} else {
			const gbSize = (size / (1024 * 1024 * 1024)).toFixed(2);
			return gbSize + ' GB';
		}
	}
}

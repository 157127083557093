import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, catchError, distinctUntilChanged, retry, take, throwError } from 'rxjs';
import { IBase } from '../interfaces/base.interface';
import { PaginatedInterface } from '../interfaces/paginated.interface';
import { ApiQueryParams } from './../../../shared/model/api-query.model';

interface HttpBaseServiceOptions {
	url: string;
	createUrl?: string;
	//	messages?: IHttpMessages;
}

@Injectable({
	providedIn: 'root',
})
export abstract class HttpBaseService {
	private readonly _url: string;
	protected readonly _createUrl: string;

	protected http = inject(HttpClient);

	constructor(options: HttpBaseServiceOptions) {
		this._url = `${environment.api}/${options.url}`;
		this._createUrl = `${environment.api}/${options.createUrl || options.url}`;
	}

	public get<T>(): Observable<T[]> {
		return this.request<T[]>('GET', this._url);
	}

	public getById<T>(id: number | string): Observable<T> {
		const url = `${this._url}/${id}`;
		return this.request<T>('GET', url);
	}

	public searchByText<T>(search: string): Observable<T[]> {
		const url = `${this._url}?search=${search}`;
		return this.http.request<T[]>('GET', url).pipe(take(1), catchError(this.handleError));
	}

	public create<T>(item: T): Observable<T> {
		if (this.hasNullableId(item)) {
			delete item['id'];
		}
		return this.http
			.request<T>('POST', this._createUrl, { body: item })
			.pipe(distinctUntilChanged(), catchError(this.handleError));
	}

	public update<T>(id: number | string, item: T): Observable<T> {
		const url = `${this._url}/${id}`;
		return this.http
			.request<T>('PUT', url, { body: item })
			.pipe(distinctUntilChanged(), catchError(this.handleError));
	}

	public archive<T>(id: number | string): Observable<T> {
		const url = `${this._url}/arquivar/${id}`;
		return this.http.request<T>('PATCH', url).pipe(distinctUntilChanged(), catchError(this.handleError));
	}

	public unarchive<T>(id: number | string): Observable<T> {
		const url = `${this._url}/desarquivar/${id}`;
		return this.http.request<T>('PATCH', url).pipe(distinctUntilChanged(), catchError(this.handleError));
	}

	public delete(id: number): Observable<void> {
		const url = `${this._url}/${id}`;
		return this.request<void>('DELETE', url);
	}

	public getPaginated<T extends IBase>(query: ApiQueryParams): Observable<PaginatedInterface<T>> {
		const url = `${this._url}${query.toQuery()}`;
		return this.request<PaginatedInterface<T>>('GET', url);
	}

	public getNextCode(): Observable<number> {
		const url = `${this._url}/proximo-codigo`;
		return this.http
			.request<number>('GET', url)
			.pipe(distinctUntilChanged(), take(1), catchError(this.handleError));
	}

	protected request<T>(method: string, path: string, data?: any): Observable<T> {
		return this.http.request<T>(method, path, { body: data }).pipe(retry(5), take(1), catchError(this.handleError));
	}

	protected handleError(error: any) {
		let errorMessage = '';
		const errorStatus = error.error.error.statusCode;

		if (errorStatus === 409 && Array.isArray(error.error?.error.message)) {
			const penduloErrors = error.error.error.message
				.map((err: any) => `${err.index}: ${err.message}`)
				.join(', ');
			errorMessage = penduloErrors;
		} else {
			const defaultMessages = {
				100: 'Continuar',
				101: 'Mudando protocolos',
				102: 'Processamento (WebDAV)',
				103: 'Protocolos de solicitação assíncrona',
				200: 'Requisição bem sucedida',
				202: 'Aceito',
				203: 'Informações não autorizadas',
				204: 'Nenhum conteúdo',
				205: 'Redefinir conteúdo',
				206: 'Conteúdo parcial',
				207: 'Status Multi (WebDAV)',
				208: 'Já reportado (WebDAV)',
				226: 'IM Usado (HTTP Delta encoding)',
				300: 'Múltiplas escolhas',
				301: 'Movido permanentemente',
				302: 'Encontrado',
				303: 'Consulte Outros',
				304: 'Não modificado',
				305: 'Use Proxy',
				306: 'Proxy Switch',
				307: 'Redirecionamento temporário',
				308: 'Redirecionamento permanente',
				400: 'Requisição inválida',
				401: 'Não autorizado',
				402: 'Pagamento necessário',
				403: 'Proibido',
				404: 'Não encontrado',
				405: 'Método não permitido',
				406: 'Não Aceitável',
				407: 'Autenticação de proxy necessária',
				408: 'Tempo de solicitação esgotado',
				409: 'Conflito',
				410: 'Desaparecido',
				411: 'Comprimento necessário',
				412: 'Pré-condição falhou',
				413: 'Entidade de solicitação muito grande',
				414: 'URI muito longo',
				415: 'Tipo de mídia não suportado',
				416: 'Solicitação de intervalo não satisfatória',
				417: 'Falha na expectativa',
				421: 'Destino de redirecionamento trancado',
				422: 'Entidade não processável',
				423: 'Bloqueado',
				424: 'Falha na Dependência',
				425: 'Coleção não ordenada',
				426: 'Upgrade Obrigatório',
				428: 'Pré-condição necessária',
				429: 'Muitas solicitações',
				431: 'Campos de cabeçalho de solicitação muito grandes',
				451: 'Indisponível por motivos legais',
				500: 'Erro interno do servidor',
				501: 'Não implementado',
				502: 'Gateway ruim',
				503: 'Serviço indisponível',
				504: 'Tempo limite do gateway',
				505: 'Versão HTTP não suportada',
				506: 'Variação também negocia',
				507: 'Armazenamento insuficiente',
				508: 'Loop detectado',
				510: 'Não estendido',
				511: 'Autenticação de rede necessária',
				default: 'Ocorreu um erro',
			};

			errorMessage = error.error.error.message || defaultMessages[errorStatus] || defaultMessages.default;
		}

		const customError = new Error(errorMessage);
		customError['status'] = errorStatus;
		customError['originalError'] = error;

		return throwError(() => customError);
	}

	private hasNullableId(item: any): boolean {
		return item.id !== undefined && item.id === null;
	}
}

<div [formGroup]="formGroup" class="w-full mt-1">
	<mat-form-field [floatLabel]="'always'" class="w-full">
		<mat-label class="font-semibold text-md mb-3">Telefone</mat-label>
		<input
			type="text"
			[formControlName]="controlName"
			matInput
			onkeypress="return /[0-9-\b-/\\(\\)]/i.test(event.key)"
		/>
		<mat-select [formControlName]="countryCode" matPrefix class="mr-1.5">
			<mat-select-trigger>
				<span class="flex items-center">
					<span
						class="w-6 h-4 mr-1"
						[style.background]="'url(\'/assets/images/flags/flags.png\') no-repeat 0 0'"
						[style.backgroundSize]="'24px 3876px'"
						[style.backgroundPosition]="getFlagCountry()?.flagImagePos"
					></span>
					<span class="sm:mx-0.5 font-medium text-default">{{ getFlagCountry()?.code }}</span>
				</span>
			</mat-select-trigger>
			<mat-option
				*ngFor="let country of countryFlags; let idx = index"
				[value]="country"
				(click)="getCountryByIso(country)"
			>
				<span class="flex items-center">
					<span
						class="w-6 h-4 overflow-hidden"
						[style.background]="'url(\'/assets/images/flags/flags.png\') no-repeat 0 0'"
						[style.backgroundSize]="'24px 3876px'"
						[style.backgroundPosition]="country.flagImagePos"
					></span>
					<span class="ml-2">{{ country.name }}</span>
					<span class="ml-2 font-medium">{{ country.code }}</span>
				</span>
			</mat-option>
		</mat-select>
	</mat-form-field>
</div>

import { Injectable } from '@angular/core';
import { TranslateParams, TranslocoService } from '@ngneat/transloco';
import { lastValueFrom } from 'rxjs';
import BaseEntity from '../entity/base.entity';
import { FuseConfirmationService } from './../../../../src/@fuse/services/confirmation/confirmation.service';
import { FuseConfirmationConfig } from './../../../../src/@fuse/services/confirmation/confirmation.types';
import { ApiService } from './api.service';

type DeleteInput = {
	data: BaseEntity;
	deleteMessage: TranslateParams | string;
	service: ApiService<any>;
};

@Injectable()
export class DeleteService {
	constructor(
		private _translate: TranslocoService,
		private _fuseConfirmationService: FuseConfirmationService,
	) {}

	async delete(input: DeleteInput): Promise<boolean> {
		const confirmationConfig: FuseConfirmationConfig = {
			actions: {
				confirm: {
					label: this._translate.translate('ACTIONS.CONFIRM'),
					color: 'warn',
				},
			},
			icon: {
				show: false,
			},
		};

		confirmationConfig.title = 'Excluir';
		confirmationConfig.message = this._translate.translate(input.deleteMessage) || input.deleteMessage.toString();
		const confirmation = this._fuseConfirmationService.open(confirmationConfig);

		const result = await lastValueFrom(confirmation.afterClosed());
		if (result === 'confirmed') {
			await lastValueFrom(input.service.delete(input.data.id));
			return true;
		}
		return false;
	}
}

/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { TranslocoService } from '@ngneat/transloco';
import { CdTimerComponent } from 'angular-cd-timer';
import { AtendimentoStatusEnum } from 'app/modules/cstools/check-in/enums/atendimento-status.enum';
import { DateParserHelper } from 'app/shared/helpers/data-parser.helper';
import { catchError, lastValueFrom, of, Subject, take, takeUntil } from 'rxjs';
import { FuseConfirmationService } from '../../../../../src/@fuse/services/confirmation';
import { UserService } from '../../../core/user/user.service';
import { User } from '../../../core/user/user.types';
import { HeaderSelectCustomerService } from '../header-select-customer/service/header-select-customer.service';
import { CheckinResetDialogComponent } from './dialog/checkin-reset-dialog/checkin-reset-dialog.component';
import { CheckinCheckoutState } from './enums/state.enum';
import { CheckinCheckoutService } from './service/checkin-checkout.service';

@Component({
	selector: 'checkin-checkout',
	templateUrl: './checkin-checkout.component.html',
	styleUrls: ['./checkin-checkout.component.scss'],
})
export class CheckinCheckoutComponent implements OnInit, OnDestroy {
	@ViewChild('checkInTimer') checkInTimer: CdTimerComponent;
	@ViewChild('drawer') drawer: FuseDrawerComponent;
	formatTimer = 'ms';
	checkInUser: any;
	user: User;
	filialId: number;
	filialName: string;
	statusCheckIn: CheckinCheckoutState;
	setStartTime: number = 0;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	constructor(
		private translate: TranslocoService,
		private _fuseConfirmationService: FuseConfirmationService,
		private _userService: UserService,
		private checkinCheckoutService: CheckinCheckoutService,
		private headerCustomerService: HeaderSelectCustomerService,
		private router: Router,
		public dialog: MatDialog,
		private _changeDetectorRef: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.checkinCheckoutService.checkinState = CheckinCheckoutState.CHECK_OUT;
		this.getUser();
		this.changeFilialByHeader();
		this.checkinCheckoutService.checkinState$.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
			this.statusCheckIn = data;
			switch (data) {
				case CheckinCheckoutState.CHECK_OUT:
					this.checkInTimer?.reset();
					this.setStartTime = 0;
					break;
				case CheckinCheckoutState.PAUSE:
					this.checkInTimer?.stop();
					break;
				case CheckinCheckoutState.RESUME:
					this.checkInTimer?.resume();
					break;
			}
		});
		this.loadLastCheckout();
	}

	isModeCheckIn(): boolean {
		return this.statusCheckIn === CheckinCheckoutState.CHECK_IN;
	}

	setCheckInOut(data: any): void {
		if (!data) {
			return;
		}
		if (data.status == AtendimentoStatusEnum.ABERTO) {
			this.checkinCheckoutService.checkinState = CheckinCheckoutState.CHECK_IN;
			this.statusCheckIn = CheckinCheckoutState.CHECK_IN;
			this.setStartTime = DateParserHelper.dateNowSecondsDiff(data.instante_checkin);
			this._changeDetectorRef.markForCheck();
		} else {
			this.checkinCheckoutService.checkinState = CheckinCheckoutState.CHECK_OUT;
		}
	}
	getUser(): void {
		this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
			this.user = user;
		});
	}

	async openModalcheckinOrCheckout(): Promise<void> {
		let msg = '';
		let title = '';
		await this.loadLastCheckout();
		if (!this.isModeCheckIn()) {
			title = this.translate.translate('MODULES.ADMIN.CHECK_IN_OR_CHECK_OUT.CHECK_IN');
			let msgLastCheckin = '';
			if (this.checkInUser?.tempo_ultimo_checkin == null || this.checkInUser?.tempo_ultimo_checkin == undefined) {
				msgLastCheckin = 'Esta unidade ainda não possui registro de atendimento';
			}
			if (this.checkInUser?.tempo_ultimo_checkin > 0) {
				msgLastCheckin = `Fazem ${this.checkInUser.tempo_ultimo_checkin} ${
					this.checkInUser.tempo_ultimo_checkin > 1 ? 'dias' : 'dia'
				} que você não entra em contato com essa unidade armazenadora.`;
			}

			msg = `<br><p>${msgLastCheckin}</p><br><p>Deseja iniciar o check-in em ${this.filialName}?</p>`;
		} else {
			title = this.translate.translate('MODULES.ADMIN.CHECK_IN_OR_CHECK_OUT.CHECK_OUT');
			msg = `<br> <p> Deseja realizar o check-out na unidade armazenadora ${this.filialName}?</p>`;
		}
		const confirmation = this._fuseConfirmationService.open({
			title: title,
			message: msg,
			actions: {
				confirm: {
					label: this.translate.translate('ACTIONS.CONFIRM'),
					color: 'primary',
				},
			},
			icon: {
				show: false,
			},
		});

		confirmation.afterClosed().subscribe(result => {
			if (result === 'confirmed') {
				if (!this.isModeCheckIn()) {
					this.checkinCheckoutService.checkIn().pipe(take(1)).subscribe();
				} else {
					this.router.navigate(['/analyst/automatic-email']).then(() => {
						this.router.navigate(['/check-in'], {
							state: {
								checkout: this.checkInTimer.get(),
								atendimento: this.checkInUser,
							},
						});
					});
				}
			}
		});
		this._changeDetectorRef.detectChanges();
	}

	openCheckInResetDialog(): void {
		this.checkInTimer.stop();
		const dialogRef = this.dialog.open(CheckinResetDialogComponent, {
			minWidth: '36rem',
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res?.success) {
				this.setStartTime = 0;
				this.checkInTimer.reset();
				setTimeout(() => {
					this.checkInTimer.start();
				}, 150);
			} else {
				this.checkInTimer.resume();
			}
		});
	}

	openCheckInPauseDialog(): void {
		const confirmation = this._fuseConfirmationService.open({
			title: this.translate.translate('MODULES.ADMIN.CHECK_IN_OR_CHECK_OUT.CHECK_IN_PAUSE_MODAL'),
			message: this.translate.translate('MODULES.ADMIN.CHECK_IN_OR_CHECK_OUT.CHECK_IN_PAUSE_CONFIRM'),
			actions: {
				confirm: {
					label: this.translate.translate('ACTIONS.CONFIRM'),
					color: 'primary',
				},
			},
			icon: {
				show: false,
			},
		});

		confirmation.afterClosed().subscribe(result => {
			if (result === 'confirmed') {
				this.checkinCheckoutService.checkInPause().pipe(take(1)).subscribe();
				this.checkinCheckoutService.checkinState = CheckinCheckoutState.PAUSE;
			}
		});
	}
	changeFilialByHeader(): void {
		this.headerCustomerService.filial$.pipe(takeUntil(this._unsubscribeAll)).subscribe((filial: any) => {
			if (filial) {
				this.filialName = filial.nome;
				this.filialId = filial.id;
			}
		});
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	private async loadLastCheckout(): Promise<void> {
		const data = await lastValueFrom(
			this.checkinCheckoutService.searchLastContact().pipe(
				take(1),
				catchError(error => {
					this.checkinCheckoutService.checkinState = CheckinCheckoutState.CHECK_OUT;
					return of(null);
				}),
			),
		);
		this.checkInUser = data;
		this.setCheckInOut(data);
		this._changeDetectorRef.detectChanges();
	}
}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { COUNTRY_FLAGS } from '../../constants/country-flags';

@Component({
	selector: 'app-country-phone',
	templateUrl: './country-phone.component.html',
})
export class CountryPhoneComponent implements OnInit {
	@Input() public formGroup: FormGroup;
	@Input() public class: string;
	@Input() public label: string;
	@Input() public controlName: string;
	@Input() public countryCode: string;

	countryFlags = COUNTRY_FLAGS;
	countryNumberSelected: any = {};

	constructor() {}

	ngOnInit(): void {
		let ddi = '+55';
		if (this.formGroup.get(this.controlName).value) {
			ddi = this.formGroup.get(this.controlName).value?.split(' ', 1)[0];
		}
		if (!ddi || ddi === '') {
			ddi = '+55';
		}
		if (ddi[0] !== '+') {
			ddi = '+55';
		}
		this.countryNumberSelected = this.countryFlags.find((country, index) => {
			if (country.code === ddi) {
				this.loadCountryNUmberSelected(index);
				return country;
			}
		});
		if (this.formGroup.get(this.controlName).value) {
			this.formGroup
				.get(this.controlName)
				.setValue(this.formGroup.get(this.controlName).value.substring(ddi.length).trim());
		}
	}

	getCountryByIso(country): any {
		this.countryNumberSelected = country;
	}
	getFlagCountry(): any {
		return this.countryFlags.find(country => country.iso === this.countryNumberSelected.iso);
	}

	loadCountryNUmberSelected(countryPosition?): void {
		const countryNumberPositionSelected = countryPosition ? countryPosition : 28;
		this.formGroup.get(this.countryCode).setValue(this.countryFlags[countryNumberPositionSelected]);
		this.countryNumberSelected = this.countryFlags[countryNumberPositionSelected];
	}
}

export class CustomFile {
	id: number;
	name: string;
	size: number;
	mimeType: string;
	type: string;
	lastModified: Date;
	s3Key: string;
	url: string;
	base64: string;
	deleted: boolean = false;
	file: File;

	constructor(partial?: Partial<CustomFile>) {
		Object.assign(this, partial);
		if (partial.name && partial.name.includes('.')) {
			this.type = partial.name.split('.')[1].toUpperCase();
		}
	}

	isS3Object(): boolean {
		return this.s3Key !== undefined && this.s3Key !== null;
	}
}

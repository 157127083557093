import { Injectable } from '@angular/core';
import { Subscriber } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class Base64ConverterService {
	constructor() {}

	readFile(file: File, subscriber: Subscriber<any>) {
		const filereader = new FileReader();

		filereader.readAsDataURL(file);

		filereader.onload = () => {
			subscriber.next(filereader.result);
			subscriber.complete();
		};

		filereader.onerror = () => {
			subscriber.error();
			subscriber.complete();
		};
	}
}

import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { AuthService } from '../../../core/auth/auth.service';
import { CheckinCheckoutState } from '../checkin-checkout/enums/state.enum';
import { CheckinCheckoutService } from '../checkin-checkout/service/checkin-checkout.service';
import { FilialModel } from './models/filial-model';
import { HeaderSelectCustomerService } from './service/header-select-customer.service';

@Component({
	selector: 'app-header-select-customer',
	templateUrl: './header-select-customer.component.html',
	styleUrls: ['./header-select-customer.component.scss'],
})
export class HeaderSelectCustomerComponent implements OnInit, OnDestroy {
	@ViewChild('singleSelect') singleSelect: MatSelect;

	filialCtrl: FormControl = new FormControl();
	filialFilterCtrl: FormControl = new FormControl();
	nextFilialBtn: boolean = false;
	nextLastFilialBtn: boolean = false;
	previousFilialBtn: boolean = false;
	previousFirstFilialBtn: boolean = false;
	filteredFiliais: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
	filiais: FilialModel[] = [];
	isSelectOpened = false;
	filialNameModal: string;
	checkIn: boolean;
	protected _onDestroy = new Subject<void>();

	constructor(
		private headerCustomerService: HeaderSelectCustomerService,
		private checkInService: CheckinCheckoutService,
		private _authService: AuthService,
		private _changeDetectorRef: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.checkInService.checkinState$.pipe(takeUntil(this._onDestroy)).subscribe(data => {
			this.checkIn = data === CheckinCheckoutState.CHECK_IN ? true : false;
			if (this.checkIn) {
				this.filialCtrl.disable();
				this.changeCheckinFilial();
			} else {
				this.filialCtrl.enable();
			}
		});
		this.headerCustomerService.branches$.subscribe(branches => {
			this.filiais = branches;
			this._changeDetectorRef.markForCheck();
			if (this.filiais.length > 0) {
				this.setInitialValue(this.filiais);
			}
		});
		this.headerCustomerService
			.getAllCustomer()
			.pipe(takeUntil(this._onDestroy))
			.subscribe(units => {
				this._changeDetectorRef.markForCheck();
				this.filiais = units.data;
				if (this.filiais.length > 0) {
					this.setInitialValue(this.filiais);
				}
			});
		this.filialFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(value => {
			this.filterFiliais();
		});
		this.filialCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(value => {});
	}
	setInitialValue(listFiliais: FilialModel[]): void {
		let breakLoop = false;
		listFiliais.forEach((filial: FilialModel, index) => {
			if (!breakLoop) {
				if (filial.id === this._authService.branchId) {
					this.filialNameModal = this.filiais[index].nome;
					this.filialCtrl.setValue(this.filiais[index]);
					this.filteredFiliais.next(this.filiais.slice());
					this.filiais[index].clique = false;
					this.headerCustomerService.filialSelected = this.filiais[index];
					this.disableOrEnablePreviousBtns(this.returnIndexFilial(filial.id));
					this.disableOrEnableNextBtns(this.returnIndexFilial(filial.id));
					breakLoop = true;
				}
			}
		});
		if (!breakLoop) {
			this.filialCtrl.setValue(this.filiais[0]);
			this.filialNameModal = this.filiais[0].nome;
			this.filteredFiliais.next(this.filiais.slice());
			this.filiais[0].clique = false;
			this.headerCustomerService.filialSelected = this.filiais[0];
			this.disableOrEnablePreviousBtns(0);
		}
	}

	selectChangeOption(event): void {
		event.value.clique = true;
		this.filialNameModal = event.value.nome;
		this._authService.branchId = event.value.id;
		this.headerCustomerService.filialSelected = event.value;
		this.disableOrEnablePreviousBtns(this.returnIndexFilial(event.value.id));
		this.disableOrEnableNextBtns(this.returnIndexFilial(event.value.id));
	}

	returnIndexFilial(id: string): number {
		let index = null;
		this.filiais.forEach((filial, position) => {
			if (id === filial.id) {
				index = position;
			}
		});
		return index;
	}

	disableOrEnablePreviousBtns(position: any): void {
		if (position === 0) {
			this.previousFilialBtn = true;
			this.previousFirstFilialBtn = true;
		}
		if (position > 0) {
			this.previousFilialBtn = false;
			this.previousFirstFilialBtn = false;
		}
	}
	disableOrEnableNextBtns(position: any): void {
		if (position === this.filiais.length - 1) {
			this.nextFilialBtn = true;
			this.nextLastFilialBtn = true;
		}
		if (position < this.filiais.length - 1) {
			this.nextFilialBtn = false;
			this.nextLastFilialBtn = false;
		}
	}

	previousFilial(): void {
		const currentPosition = this.returnIndexFilial(this.filialCtrl.value.id);
		const previousFilial = this.filiais[currentPosition - 1];
		if (currentPosition > 0) {
			this.filialNameModal = this.filiais[currentPosition - 1].nome;
			this.filiais[currentPosition - 1].clique = true;
			this.filialCtrl.setValue(previousFilial);
			this._authService.branchId = previousFilial.id;
			this.headerCustomerService.filialSelected = previousFilial;
			this.filteredFiliais.next(this.filiais.slice());
		}
		this.disableOrEnablePreviousBtns(currentPosition - 1);
		this.disableOrEnableNextBtns(currentPosition - 1);
	}

	previousFirstFiliais(): void {
		this.filialNameModal = this.filiais[0].nome;
		this.filiais[0].clique = true;
		const firstFilial = this.filiais[0];
		this.filialCtrl.setValue(firstFilial);
		this._authService.branchId = firstFilial.id;
		this.headerCustomerService.filialSelected = firstFilial;
		this.filteredFiliais.next(this.filiais.slice());
		this.disableOrEnablePreviousBtns(0);
		this.disableOrEnableNextBtns(0);
	}

	nextFilial(): void {
		const currentPosition = this.returnIndexFilial(this.filialCtrl.value.id);
		this.filialNameModal = this.filiais[currentPosition + 1].nome;
		this.filiais[currentPosition + 1].clique = true;
		const nextFilial = this.filiais[currentPosition + 1];
		if (currentPosition < this.filiais.length - 1) {
			this.filialCtrl.setValue(nextFilial);
			this._authService.branchId = nextFilial.id;
			this.headerCustomerService.filialSelected = nextFilial;
			this.filteredFiliais.next(this.filiais.slice());
			this.previousFilialBtn = false;
			this.previousFirstFilialBtn = false;
		}
		this.disableOrEnablePreviousBtns(currentPosition + 1);
		this.disableOrEnableNextBtns(currentPosition + 1);
	}

	nextLastFiliais(): void {
		const lastPosition = this.filiais.length - 1;
		this.filialNameModal = this.filiais[lastPosition].nome;
		this.filiais[lastPosition].clique = true;
		const lastFilial = this.filiais[lastPosition];
		this.filialCtrl.setValue(lastFilial);
		this._authService.branchId = lastFilial.id;
		this.disableOrEnablePreviousBtns(lastPosition);
		this.disableOrEnableNextBtns(lastPosition);
		this.headerCustomerService.filialSelected = lastFilial;
		this.filteredFiliais.next(this.filiais.slice());
	}

	changeCheckinFilial(): void {
		this.checkInService.searchLastContact().subscribe(data => {
			if (data.unidade_id) {
				this._authService.branchId = data.unidade_id;
				const currentPosition = this.returnIndexFilial(data.unidade_id);
				const filial = this.filiais[currentPosition];
				if (currentPosition > 0) {
					this.filialNameModal = this.filiais[currentPosition].nome;
					this.filiais[currentPosition].clique = true;
					this.filialCtrl.setValue(filial);
					this.headerCustomerService.filialSelected = filial;
					this.filteredFiliais.next(this.filiais.slice());
					this._changeDetectorRef.markForCheck();
				}
			}
		});
	}

	getTooltipContent(): string {
		const selectedFilial = this.filialCtrl.value;
		if (selectedFilial) {
			return `${selectedFilial.cliente.nome}: (${selectedFilial.nome}) ${selectedFilial.cidade.nome} - ${selectedFilial.cidade.uf.sigla}`;
		} else {
			return '';
		}
	}

	ngOnDestroy(): void {
		this._onDestroy.next();
		this._onDestroy.complete();
	}

	branchSelectClick(value: boolean): void {
		this.isSelectOpened = value;
	}

	protected filterFiliais(): void {
		if (!this.filiais) {
			return;
		}
		let search = this.filialFilterCtrl.value;
		if (!search) {
			this.filteredFiliais.next(this.filiais.slice());
			return;
		} else {
			search = search.toLowerCase();
		}
		this.filteredFiliais.next(
			this.filiais.filter((filial: FilialModel) => filial.nome.toLowerCase().indexOf(search) > -1),
		);
	}
}

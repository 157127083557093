import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, switchMap, take, tap, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { UserService } from '../user/user.service';
import { LoggedUserInterface } from 'app/shared/interfaces';

const API_AUTH = `${environment.api}/auth`;

@Injectable()
export class AuthService {
	private _authenticated: boolean = false;

	constructor(
		private _httpClient: HttpClient,
		private _userService: UserService,
	) {}

	set accessToken(token: string) {
		if (token !== undefined) {
			localStorage.setItem('accessToken', token);
		}
	}

	set refreshToken(token: string) {
		if (token !== undefined) {
			localStorage.setItem('refreshToken', token);
		}
	}

	set branchId(uuid: string) {
		if (uuid !== undefined) {
			localStorage.setItem('branchId', uuid);
		}
	}

	get accessToken(): string {
		return localStorage.getItem('accessToken') ?? '';
	}

	get refreshToken(): string {
		return localStorage.getItem('refreshToken') ?? '';
	}

	get branchId() {
		return localStorage.getItem('branchId') ?? '';
	}

	forgotPassword(email: string): Observable<any> {
		return this._httpClient.post('api/auth/forgot-password', email);
	}

	resetPassword(password: string): Observable<any> {
		return this._httpClient.post('api/auth/reset-password', password);
	}

	signIn(credentials: { email: string; senha: string }): Observable<any> {
		if (this._authenticated) {
			return throwError('User is already logged in.');
		}
		return this._httpClient.post(`${API_AUTH}/login`, credentials).pipe(
			switchMap((response: LoggedUserInterface) => {
				this.accessToken = response.token.access_token;
				this.refreshToken = response.token.hash_token;
				this.branchId = response.unidade.id;
				this._authenticated = true;
				this._userService.user = response.usuario;
				return of(response);
			}),
		);
	}

	signInUsingToken(): Observable<any> {
		return this._httpClient
			.post(`${API_AUTH}/refresh-token`, {
				oldToken: this.refreshToken,
			})
			.pipe(
				tap((response: any) => {
					response?.usuario && localStorage.setItem('usuario', JSON.stringify(response.usuario));
				}),
				catchError(() => {
					//Sign out
					this.signOut();

					//Reload the app
					location.reload();

					return of(false);
				}),
				switchMap((response: LoggedUserInterface) => {
					if (!response) {
						return of(response);
					}
					this.accessToken = response.token.access_token;
					this.refreshToken = response.token?.hash_token;
					this._authenticated = true;
					this._userService.user = {
						id: response.usuario.id,
						nome: response.usuario.nome,
						sobrenome: response.usuario.sobrenome,
						email: response.usuario.email,
					};
					return of(this.accessToken);
				}),
			);
	}

	signOut(): Observable<any> {
		this._httpClient
			.post(`${API_AUTH}/logout`, {
				oldToken: this.refreshToken,
			})
			.pipe(take(1))
			.subscribe();

		localStorage.removeItem('accessToken');
		localStorage.removeItem('appSelected');
		localStorage.removeItem('refreshToken');
		this._authenticated = false;
		return of(true);
	}

	signUp(user: { name: string; email: string; password: string; company: string }): Observable<any> {
		return this._httpClient.post('api/auth/sign-up', user);
	}

	unlockSession(credentials: { email: string; password: string }): Observable<any> {
		return this._httpClient.post('api/auth/unlock-session', credentials);
	}

	check(): Observable<boolean> {
		if (this._authenticated) {
			return of(true);
		}

		if (!this.accessToken) {
			return of(false);
		}
		//todo - validar
		// if (!environment.local) {
		// 	if (AuthUtils.isTokenExpired(this.accessToken)) {
		// 		this.signInUsingToken();
		// 	}

		// 	return this.signInUsingToken();
		// }

		return of(true);
	}
}

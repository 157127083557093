import { Route } from '@angular/router';
import { InitialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
	// Redirect empty path to '/example'
	{ path: '', pathMatch: 'full', redirectTo: 'apps-menu' },

	// Redirect signed in user to the '/dashboard'
	//
	// After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
	// path. Below is another redirection for that path to redirect the user to the desired
	// location. This is a small convenience to keep all main routes together here on this file.
	{
		path: 'signed-in-redirect',
		pathMatch: 'full',
		redirectTo: 'apps-menu',
	},

	// Auth routes for guests
	{
		path: '',
		canActivate: [NoAuthGuard],
		canActivateChild: [NoAuthGuard],
		component: LayoutComponent,
		data: {
			layout: 'empty',
		},
		children: [
			{
				path: 'confirmation-required',
				loadChildren: () =>
					import('../app/modules/auth/confirmation-required/confirmation-required.module').then(
						m => m.AuthConfirmationRequiredModule,
					),
			},
			{
				path: 'forgot-password',
				loadChildren: () =>
					import('../app/modules/auth/forgot-password/forgot-password.module').then(
						m => m.AuthForgotPasswordModule,
					),
			},
			{
				path: 'reset-password',
				loadChildren: () =>
					import('../app/modules/auth/reset-password/reset-password.module').then(
						m => m.AuthResetPasswordModule,
					),
			},
			{
				path: 'sign-in',
				loadChildren: () => import('../app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule),
			},
			{
				path: 'sign-up',
				loadChildren: () => import('../app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule),
			},
		],
	},

	// Auth routes for authenticated users
	{
		path: '',
		canActivate: [AuthGuard],
		canActivateChild: [AuthGuard],
		component: LayoutComponent,
		data: {
			layout: 'empty',
		},
		children: [
			{
				path: 'sign-out',
				loadChildren: () =>
					import('../app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule),
			},
			{
				path: 'unlock-session',
				loadChildren: () =>
					import('../app/modules/auth/unlock-session/unlock-session.module').then(
						m => m.AuthUnlockSessionModule,
					),
			},
		],
	},

	// Landing routes
	{
		path: '',
		component: LayoutComponent,
		data: {
			layout: 'empty',
		},
		children: [
			{
				path: 'home',
				loadChildren: () => import('../app/modules/landing/home/home.module').then(m => m.LandingHomeModule),
			},
		],
	},

	// BO Tools routes
	{
		path: '',
		canActivate: [AuthGuard],
		// canActivateChild: [AuthGuard],
		component: LayoutComponent,
		resolve: {
			initialData: InitialDataResolver,
		},
		children: [
			{
				path: 'auxiliary-equipment',
				loadChildren: () =>
					import('../app/modules/botools/auxiliary-equipment/auxiliary-equipment.module').then(
						m => m.AuxiliaryEquipmentModule,
					),
			},
			{
				path: 'branch',
				loadChildren: () => import('../app/modules/botools/branch/branch.module').then(m => m.BranchModule),
			},
			{
				path: 'instalacao',
				loadComponent: () =>
					import('../app/modules/botools/instalacao/page/instalacao.page').then(m => m.InstalacaoPage),
			},
			{
				path: 'checklist',
				loadChildren: () =>
					import('../app/modules/botools/checklist/checklist.module').then(m => m.ChecklistModule),
			},
			{
				path: 'contract',
				loadChildren: () =>
					import('../app/modules/botools/contract/contract.module').then(m => m.ContractModule),
			},
			{
				path: 'customer',
				loadChildren: () =>
					import('../app/modules/botools/customer/customer.module').then(m => m.CustomerModule),
			},
			// {
			// 	path: 'secador',
			// 	loadComponent: () =>
			// 		import('../app/modules/botools/secador/page/secador.page').then(m => m.SecadorPage),
			// },
			{
				path: 'secador',
				loadComponent: () =>
					import('../app/modules/botools/secador-new/page/secador.page').then(m => m.SecadorPage),
			},
			{
				path: 'local-weatherstation',
				loadChildren: () =>
					import('../app/modules/botools/local-weatherstation/local-weatherstation.module').then(
						m => m.LocalWeatherstationModule,
					),
			},
			{
				path: 'location',
				loadChildren: () =>
					import('../app/modules/botools/location/location.module').then(m => m.LocationModule),
			},
			{
				path: 'main-equipment',
				loadChildren: () =>
					import('../app/modules/botools/main-equipment/main-equipment.module').then(
						m => m.MainEquipmentModule,
					),
			},
			{
				path: 'main-equipment-new',
				loadComponent: () =>
					import('../app/modules/botools/main-equipment_new/page/main-equipment.page').then(
						m => m.MainEquipmentPage,
					),
			},
			{
				path: 'gerenciamento-servico',
				loadComponent: () =>
					import('../app/modules/botools/gerenciamento-servico/page/gerenciamento-servico.page').then(
						m => m.GerenciamentoServicoPage,
					),
			},
			{
				path: 'manufacturer',
				loadChildren: () =>
					import('../app/modules/botools/manufacturer/manufacturer.module').then(m => m.ManufacturerModule),
			},
			{
				path: 'remote-weatherstation',
				loadComponent: () =>
					import('../app/modules/botools/remote-weatherstation/page/remote-weatherstation.page').then(
						m => m.RemoteWeatherstationPage,
					),
			},
			{
				path: 'silo',
				loadComponent: () =>
					import('../app/modules/botools/estrutura-armazenagem/silo/page/silo.page').then(m => m.SiloPage),
			},
			{
				path: 'armazem',
				loadComponent: () =>
					import('../app/modules/botools/estrutura-armazenagem/armazem/page/armazem.page').then(
						m => m.ArmazemPage,
					),
			},
			// {
			// 	path: 'warehouse',
			// 	loadChildren: () =>
			// 		import('./modules/botools/storage-structure/warehouse/warehouse.module').then(
			// 			m => m.WarehouseModule,
			// 		),
			// },
			// {
			// 	path: 'warehouse-v2',
			// 	loadComponent: () =>
			// 		import('../app/modules/botools/estrutura-armazenagem/armazem/page/armazem.page').then(
			// 			m => m.ArmazemPage,
			// 		),
			// },
			{
				path: 'transceiver',
				loadComponent: () =>
					import('../app/modules/botools/transceptor/page/transceiver.page').then(m => m.TransceiverPage),
			},
			{
				path: 'estacao-ordem-consulta',
				loadComponent: () =>
					import('./modules/botools/estacao-ordem-consulta/page/estacao-ordem-consulta.page').then(
						m => m.EstacaoOrdemConsultaPage,
					),
			},
			{
				path: 'apps-menu',
				data: {
					layout: 'empty',
				},
				loadChildren: () =>
					import('../app/modules/auth/apps-menu/apps-menu.module').then(m => m.AppsMenuModule),
			},
			{
				path: 'config/user',
				loadChildren: () => import('../app/modules/cstools/user/user.module').then(m => m.UserModule),
			},
			{
				path: 'analyzer/silos',
				loadChildren: () =>
					import('../app/modules/cstools/analyzer/silo/silo-analyzer.module').then(m => m.AnalyzerModule),
			},
			{
				path: 'analyzer/weatherstations',
				loadChildren: () =>
					import('../app/modules/cstools/analyzer/weather-station/weather-station-analyzer.module').then(
						m => m.AnalyzerModule,
					),
			},
			{
				path: 'analyzer/equipments',
				loadChildren: () =>
					import('../app/modules/cstools/analyzer/equipment/equipment-analyzer.module').then(
						m => m.AnalyzerModule,
					),
			},
			{
				path: 'analyzer/ceres-legado',
				loadChildren: () =>
					import('../app/modules/cstools/analyzer/secador-legado/secador-analyzer.module').then(
						m => m.SecadorAnalyzerModule,
					),
			},
			{
				path: 'analyzer/secadores',
				loadComponent: () =>
					import('../app/modules/cstools/analyzer/secador/page/secador.page').then(m => m.SecadorPage),
			},
			{
				path: 'check-in',
				loadComponent: () => import('./modules/cstools/check-in/page/check-in.page').then(m => m.CheckInPage),
			},
			{
				path: 'produtos-complementares',
				loadComponent: () =>
					import('../app/modules/botools/produtos-complementares/page/produtos-complementares.page').then(
						m => m.ProdutosComplementaresPage,
					),
			},
			{
				path: 'analyst/automatic-email',
				loadChildren: () =>
					import('./modules/cstools/analyst/automatic-email/automatic-email.module').then(
						m => m.AutomaticEmailModule,
					),
			},
			{
				path: 'analyst/general-information',
				loadChildren: () =>
					import('./modules/cstools/general-info/general-info.module').then(m => m.GeneralInfoModule),
			},
			{
				path: 'estacao-proxima',
				loadComponent: () =>
					import('../app/modules/botools/estacao-proxima/page/estacao-proxima.page').then(
						m => m.EstacaoProximaPage,
					),
			},
			{
				path: 'mobile-aerator',
				loadChildren: () =>
					import('../app/modules/botools/mobile-aerator/mobile-aerator.module').then(
						m => m.MobileAeratorModule,
					),
			},
			{
				path: 'pendulum-exchanges',
				loadComponent: () =>
					import('../app/modules/botools/pendulum-exchanges-new/page/pendulum-exchanges.page').then(
						m => m.PendulumExchangesPage,
					),
			},
			{
				path: 'config/branch-group',
				loadChildren: () =>
					import('../app/modules/cstools/branch-group/branch-group.module').then(m => m.BranchGroupModule),
			},
		],
	},
];

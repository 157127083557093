import { BooleanInput } from '@angular/cdk/coercion';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { environment } from 'environments/environment';
import { Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'user',
	templateUrl: './user.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs: 'user',
})
export class UserComponent implements OnInit, OnDestroy {
	/* eslint-disable @typescript-eslint/naming-convention */
	static ngAcceptInputType_showAvatar: BooleanInput;
	/* eslint-enable @typescript-eslint/naming-convention */

	@Input() showAvatar: boolean = true;
	user: User;
	appSelected: string;
	isLocal: boolean = false;

	private _unsubscribeAll: Subject<any> = new Subject<any>();

	constructor(
		private _changeDetectorRef: ChangeDetectorRef,
		private _router: Router,
		private _userService: UserService,
	) {}

	ngOnInit(): void {
		this.isLocal = environment.local;
		// Subscribe to user changes
		this._userService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: User) => {
			this.user = user;
			// Mark for check
			this.populateDataWithLocalstorage();
			this._changeDetectorRef.markForCheck();
		});
		if (this.user) {
			this._userService
				.getUser(this.user.id)
				.pipe(takeUntil(this._unsubscribeAll))
				.subscribe((user: User) => {
					this.user = user;
					this._changeDetectorRef.markForCheck();
				});
		}
		this.loadUser();
	}

	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	updateUserStatus(status: string): void {
		// Return if user is not available
		if (!this.user) {
			return;
		}

		// Update the user
		this._userService
			.update({
				...this.user,
				status,
			})
			.subscribe();
	}

	signOut(): void {
		this._router.navigate(['/sign-out']);
	}

	loadMenu(): void {
		this.appSelected = localStorage.getItem('appSelected');
	}

	appChange(): void {
		if (localStorage.getItem('appSelected') === 'cstools') {
			localStorage.setItem('appSelected', 'botools');
			this._router.navigate(['/customer']);
		} else {
			localStorage.setItem('appSelected', 'cstools');
			this._router.navigate(['/analyzer/silos']);
		}
		setTimeout(() => {
			window.location.reload();
		}, 100);
	}

	private populateDataWithLocalstorage(): void {
		const storedUser = JSON.parse(localStorage.getItem('usuario') || '{}');

		if (!this.user.nome) {
			this.user.nome = storedUser.nome || '';
		}
		if (!this.user.sobrenome) {
			this.user.sobrenome = storedUser.sobrenome || '';
		}
		if (!this.user.email) {
			this.user.email = storedUser.email || '';
		}
	}

	private loadUser(): void {
		const storedUser = JSON.parse(localStorage.getItem('usuario') || '{}');
		if (storedUser && storedUser.id) {
			this._userService.getUser(storedUser.id).subscribe((user: User) => {
				this.user = user;
				this.populateDataWithLocalstorage();
				this._changeDetectorRef.markForCheck();
			});
		}
	}
}

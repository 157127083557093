import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { appConfig } from 'app/core/config/app.config';
import { CoreModule } from 'app/core/core.module';
import { LayoutModule } from 'app/layout/layout.module';

import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { FuseModule } from '@fuse/fuse.module';
import { FuseConfigModule } from '@fuse/services/config/config.module';
import { ToastrModule } from 'ngx-toastr';
import { LoaderComponent } from './core/base/components/loader/component/loader.component';
import { LoaderInterceptor } from './core/base/interceptors/loader.interceptor';

registerLocaleData(localePt);

const routerConfig: ExtraOptions = {
	preloadingStrategy: PreloadAllModules,
	scrollPositionRestoration: 'enabled',
};

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule.forRoot(appRoutes, routerConfig),

		LoaderComponent,

		// Fuse, FuseConfig & FuseMockAPI
		FuseModule,
		FuseConfigModule.forRoot(appConfig),

		// Core module of your application
		CoreModule,

		// Layout module of your application
		LayoutModule,

		ToastrModule.forRoot(),
	],
	providers: [
		{ provide: LOCALE_ID, useValue: 'pt' },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LoaderInterceptor,
			multi: true,
		},
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

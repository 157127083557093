import { ChangeDetectorRef, Component } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LoaderService } from '../service/loader.service';

@Component({
	selector: 'app-loader',
	standalone: true,
	templateUrl: './loader.component.html',
	imports: [MatProgressBarModule],
})
export class LoaderComponent {
	isLoading = false;

	constructor(
		private loaderService: LoaderService,
		private cd: ChangeDetectorRef,
	) {
		this.loaderService.loaderSubject.subscribe((status: boolean) => {
			this.isLoading = status;
			this.cd.detectChanges();
		});
	}
}

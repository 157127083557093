import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnInit,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
	ViewEncapsulation,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { Subject, take, takeUntil } from 'rxjs';
import { MessagesService } from './messages.service';
import { Message } from './messages.types';

@Component({
	selector: 'messages',
	templateUrl: './messages.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs: 'messages',
})
export class MessagesComponent implements OnInit {
	@ViewChild('messagesOrigin') private _messagesOrigin: MatButton;
	@ViewChild('messagesPanel') private _messagesPanel: TemplateRef<any>;

	messages: any;

	unreadCount: number;

	private _overlayRef: OverlayRef;
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	static _calculateUnreadCount: any;

	constructor(
		private _changeDetectorRef: ChangeDetectorRef,
		private _messagesService: MessagesService,
		private _overlay: Overlay,
		private _viewContainerRef: ViewContainerRef,
	) {}

	ngOnInit(): void {
		this._calculateUnreadCount();

		this._messagesService.emitNewMsg$.pipe(takeUntil(this._unsubscribeAll)).subscribe(data => {
			if (data) {
				this._calculateUnreadCount();
			}
		});
	}

	openPanel(): void {
		this._messagesService
			.getAll()
			.pipe(take(1))
			.subscribe((messages: Message[]) => {
				this.messages = messages;
			});

		this.markAllAsRead();
		this._calculateUnreadCount();
		if (!this._messagesPanel || !this._messagesOrigin) {
			return;
		}
		if (!this._overlayRef) {
			this._createOverlay();
		}
		this._overlayRef.attach(new TemplatePortal(this._messagesPanel, this._viewContainerRef));
	}

	closePanel(): void {
		this._overlayRef.detach();
	}

	markAllAsRead(): void {
		this._messagesService
			.markAllAsRead()
			.pipe(take(1))
			.subscribe(() => this._calculateUnreadCount());
	}

	private _createOverlay(): void {
		// Create the overlay
		this._overlayRef = this._overlay.create({
			hasBackdrop: true,
			backdropClass: 'fuse-backdrop-on-mobile',
			scrollStrategy: this._overlay.scrollStrategies.block(),
			positionStrategy: this._overlay
				.position()
				.flexibleConnectedTo(this._messagesOrigin._elementRef.nativeElement)
				.withLockedPosition(true)
				.withPush(true)
				.withPositions([
					{
						originX: 'start',
						originY: 'bottom',
						overlayX: 'start',
						overlayY: 'top',
					},
					{
						originX: 'start',
						originY: 'top',
						overlayX: 'start',
						overlayY: 'bottom',
					},
					{
						originX: 'end',
						originY: 'bottom',
						overlayX: 'end',
						overlayY: 'top',
					},
					{
						originX: 'end',
						originY: 'top',
						overlayX: 'end',
						overlayY: 'bottom',
					},
				]),
		});

		// Detach the overlay from the portal on backdrop click
		this._overlayRef.backdropClick().subscribe(() => {
			this._overlayRef.detach();
		});
	}

	private _calculateUnreadCount(): void {
		this._messagesService
			.getNews()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(messages => {
				this._messagesService.emitNewMsg = false;
				this.unreadCount = messages;
				this._changeDetectorRef.markForCheck();
			});
	}
}

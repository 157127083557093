import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../components/loader/service/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
	constructor(private loaderService: LoaderService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		this.loaderService.showLoader();
		// this.disableButtons();
		return next.handle(req).pipe(
			finalize(() => {
				this.loaderService.hideLoader();
				// this.enableButtons();
			}),
		);
	}

	private disableButtons(): void {
		const form = document.querySelector('form');
		if (form) {
			const buttons = form.querySelectorAll('button') as NodeListOf<HTMLButtonElement>;
			buttons.forEach(button => {
				button.disabled = true;
				button.style.opacity = '0.5';
			});
		}
	}

	private enableButtons(): void {
		const form = document.querySelector('form');
		if (form) {
			const buttons = form.querySelectorAll('button') as NodeListOf<HTMLButtonElement>;
			buttons.forEach(button => {
				button.disabled = false;
				button.style.opacity = '1';
			});
		}
	}
}

/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FuseConfirmationConfig, FuseConfirmationService } from '@fuse/services/confirmation';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { lastValueFrom } from 'rxjs';
import { CustomFile } from './model/custom-file.model';

@Component({
	selector: 'app-custom-file',
	templateUrl: './custom-file.component.html',
	styleUrls: ['./custom-file.component.scss'],
})
export class CustomFileComponent implements OnInit, OnChanges, OnDestroy {
	@Input() public files: CustomFile[];
	@Input() public convertToBase64: boolean = false;
	@Input() public boxStyle: boolean = false;
	@Input() public noDetails: boolean = false;
	@Input() public fileTypes: string[];
	@Input() public maxFileSizeMB: number;
	@Input() public multiFiles: boolean = true;
	@Output() openFile = new EventEmitter<CustomFile>();

	archivedControl: FormControl = new FormControl();
	messageWarning: string = '';

	constructor(private _fuseConfirmationService: FuseConfirmationService) {}

	ngOnInit(): void {
		if (this.maxFileSizeMB) {
			this.messageWarning = `Tamanho máximo ${this.maxFileSizeMB}MB. `;
		}
		if (this.fileTypes) {
			this.messageWarning += `Extens${this.fileTypes.length > 1 ? 'ões' : 'ão'} permitid${
				this.fileTypes.length > 1 ? 'as' : 'a'
			}: ${this.fileTypes.join(', ')}.`;
		}
	}

	ngOnChanges(changes: SimpleChanges): void {}

	ngOnDestroy(): void {}

	dropped(files: NgxFileDropEntry[]): void {
		if (this.multiFiles == false && this.files.length == 1) {
			return;
		}
		for (const droppedFile of files) {
			if (droppedFile.fileEntry.isFile) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				fileEntry.file((file: File) => {
					if (!this.validFile(file)) {
						return;
					}
					if (!this.validFile(file)) {
						return;
					}
					let customFile = new CustomFile({
						name: file.name,
						lastModified: new Date(file.lastModified),
						size: file.size,
						mimeType: file.type,
						file,
					});

					if (this.convertToBase64) {
						customFile = this.toBase64(customFile);
					}
					this.files.push(customFile);
				});
			}
		}
	}

	validFile(file: File): boolean {
		if (
			this.fileTypes &&
			!this.fileTypes.some(value => value.toLocaleLowerCase() === file.name.split('.').pop().toLowerCase())
		) {
			return false;
		}

		if (this.maxFileSizeMB && file.size > this.maxFileSizeMB * 1024 * 1024) {
			return false;
		}
		return true;
	}

	async removeFile(file: CustomFile): Promise<void> {
		if (!file.id) {
			this.files.splice(this.files.indexOf(file), 1);
			return;
		}
		if (await this.confirmRemove()) {
			this.files[this.files.indexOf(file)].deleted = true;
		}
	}

	formatSize(bytes: number): string {
		let valueInMegabytes = 0;
		valueInMegabytes = bytes / (1024 * 1024);
		return `${valueInMegabytes.toFixed(2)} MB`;
	}

	getClassByType(item: CustomFile): string {
		switch (item.type) {
			case 'PDF':
				return 'bg-red-600';
			case 'DOC':
				return 'bg-blue-600';
			case 'XLS':
				return 'bg-green-600';
			case 'PNG':
				return 'bg-amber-600';
			case 'JPG':
				return 'bg-amber-600';
			case 'JPEG':
				return 'bg-amber-600';
			default:
				return 'bg-gray-600';
		}
	}
	fileClick(file: CustomFile): void {
		if (file.id) {
			this.openFile.emit(file);
		}
	}

	returnFile(file: CustomFile): void {
		this.openFile.emit(file);
	}

	listFiles(): CustomFile[] {
		return this.files.filter(file => !file.deleted);
	}

	private toBase64(file: CustomFile): CustomFile {
		const reader = new FileReader();
		reader.onload = () => {
			const base64String = reader.result as string;
			file.base64 = base64String;
		};
		reader.readAsDataURL(file.file);
		return file;
	}

	private async confirmRemove(): Promise<boolean> {
		const confirmationConfig: FuseConfirmationConfig = {
			actions: {
				confirm: {
					label: 'Confirmar',
					color: 'primary',
				},
			},
			icon: {
				show: true,
			},
		};

		confirmationConfig.icon.name = 'archive';
		confirmationConfig.title = 'Remover Arquivo';
		confirmationConfig.message = 'Deseja realmente remover o arquivo?';
		const confirmation = this._fuseConfirmationService.open(confirmationConfig);
		const result = await lastValueFrom(confirmation.afterClosed());
		return result === 'confirmed';
	}
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Observable, of } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class InitialDataResolver implements Resolve<any> {
	/**
	 * Constructor
	 */
	constructor(private _navigationService: NavigationService) {}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Use this resolver to resolve initial mock-api for the application
	 *
	 * @param route
	 * @param state
	 */
	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		// Fork join multiple API endpoint calls to wait all of them to finish
		return of({});
		// return forkJoin([
		//     this._navigationService.get(),
		//     this._messagesService.getAll(),
		//     this._notificationsService.getAll(),
		//     this._quickChatService.getChats(),
		//     this._shortcutsService.getAll()
		// ]);
	}
}

<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
	*ngIf="menuLoaded"
	class="dark bg-gray-900 print:hidden fvn"
	[appearance]="navigationAppearance"
	[mode]="isScreenSmall ? 'over' : 'side'"
	[name]="'mainNavigation'"
	[navigation]="navigation.default"
	[opened]="!isScreenSmall"
>
	<!-- Navigation header hook -->
	<ng-container fuseVerticalNavigationContentHeader>
		<!-- Logo -->
		<div class="flex items-center h-20 p-2 pb-0 child">
			<!-- Light version -->
			<!-- <img class="dark:hidden ml-0" src="assets/images/logo/logo-navbar.svg" alt="Procer" /> -->
			<!-- Dark version -->
			<!-- <img class="hidden dark:flex ml-0" src="assets/images/logo/logo-navbar.svg" alt="Procer" /> -->
		</div>
	</ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">
	<!-- Header -->
	<div
		class="menuHeader flex flex-auto sm:flex-row flex-col w-full min-w-12 sm:max-h-16 max-h-52 p-3 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
	>
		<div class="flex sm:w-[5%] w-[100%]">
			<button mat-icon-button (click)="toggleNavigation('mainNavigation')">
				<mat-icon [svgIcon]="'heroicons_outline:menu'"></mat-icon>
			</button>
		</div>
		<div class="flex flex-auto sm:w-[75%] w-[100%] items-center justify-center">
			@if (isLocal) {
				<h1>{{ unidadeClienteNome }}</h1>
			} @else {
				<app-header-select-customer></app-header-select-customer>
			}
		</div>
		<div
			class="sm:w-[210px] w-[100%] flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2 font-semibold text-sm text-[#64748B] sm:justify-end justify-center"
		>
			<checkin-checkout *ngIf="isCSTools"></checkin-checkout>
			<unit-summary></unit-summary>
			<messages></messages>
			<user></user>
		</div>
	</div>

	<!-- Content -->
	<div class="flex flex-col flex-auto">
		<router-outlet *ngIf="true"></router-outlet>
	</div>

	<!-- Footer -->
	<div
		*ngIf="!isScreenMobile"
		class="relative flex flex-0 items-center justify-between w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden"
	>
		<span class="font-medium text-secondary">{{ appName }}</span>
		@if (devEnviromentMode) {
			<div
				class="text-rose-400 tracking-widest text-center font-semibold text-[12px] ml-5 mr-4 p-2 rounded-md bg-red-500/[0.14]"
			>
				AMBIENTE DE DESENVOLVIMENTO
			</div>
		}
		@if (hmlEnviromentMode) {
			<div
				class="text-lime-500 tracking-widest text-center font-semibold text-[12px] ml-5 mr-4 p-2 rounded-md bg-green-500/[0.14]"
			>
				AMBIENTE DE HOMOLOGAÇÃO
			</div>
		}
		<span class="font-medium text-secondary">Procer &copy; {{ currentYear }}</span>
	</div>
</div>

export class ApiPaginatedResponseModelMeta {
	itemsPerPage: number;
	totalItems: number;
	currentPage: number;
	totalPages: number;
}

export class ApiPaginatedResponseModelData<T> {
	data: T[];
	meta: ApiPaginatedResponseModelMeta;

	constructor(partial?: Partial<ApiPaginatedResponseModelData<T>>) {
		Object.assign(this, partial);
	}
}

export class ApiPaginatedResponseModel<T> extends ApiPaginatedResponseModelData<T> {}

export class ApiResponseModel<T> {
	data: T;
	constructor(partial?: Partial<ApiPaginatedResponseModel<T>>) {
		Object.assign(this, partial);
	}
}

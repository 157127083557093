import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs';
import { CheckinCheckoutService } from '../../service/checkin-checkout.service';

@Component({
	selector: 'app-checkin-reset-dialog',
	templateUrl: './checkin-reset-dialog.component.html',
	styleUrls: ['./checkin-reset-dialog.component.scss'],
})
export class CheckinResetDialogComponent {
	reason: string;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: string,
		public dialog: MatDialogRef<CheckinResetDialogComponent>,
		private service: CheckinCheckoutService,
	) {}

	close(): void {
		this.dialog.close({ success: false });
	}

	save(): void {
		this.service
			.checkInReset(this.reason)
			.pipe(take(1))
			.subscribe((res: any) => {
				if (res) {
					this.dialog.close({ success: true });
				} else {
					this.dialog.close({ success: false });
				}
			});
	}
}

/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
// Examples
// ?filter.name=$eq:Milo is equivalent with ?filter.name=Milo
// ?filter.age=$btw:4,6 where column age is between 4 and 6
// ?filter.id=$not:$in:2,5,7 where column id is not 2, 5 or 7
// ?filter.summary=$not:$ilike:term where column summary does not contain term
// ?filter.summary=$sw:term where column summary starts with term
// ?filter.seenAt=$null where column seenAt is NULL
// ?filter.seenAt=$not:$null where column seenAt is not NULL
// ?filter.createdAt=$btw:2022-02-02,2022-02-10 where column createdAt is between the dates 2022-02-02 and 2022-02-10

// Mullti Filter Examples
// ?filter.id=$gt:3&filter.id=$lt:5 where column id is greater than 3 and less than 5
// ?filter.id=$gt:3&filter.id=$or:$lt:5 where column id is greater than 3 or less than 5
// ?filter.id=$gt:3&filter.id=$and:$lt:5&filter.id=$or:$eq:7 where column id is greater than 3 and less than 5 or equal to 7
// Note: the and operators are not required. The above example is equivalent to:
// ?filter.id=$gt:3&filter.id=$lt:5&filter.id=$or:$eq:7
// Note: The first comparator on the the first filter is ignored because the filters are grouped by the column name and chained with an and to other filters.
// ...&filter.id=5&filter.id=$or:7&filter.name=Milo&...

// https://www.npmjs.com/package/nestjs-paginate

const DEFAULT_PAGE_LIMIT = 10;

export enum QueryFilterOperator {
	EQ = '$eq',
	NULL = '$null',
	IN = '$in',
	GT = '$gt',
	GTE = '$gte',
	LT = '$lt',
	LTE = '$lte',
	BTW = '$btw',
	ILIKE = '$ilike',
	SW = '$sw',
	AND = '$and',
	OR = '$or',
	NOT = '$not',
}

export interface ApiQueryPagination {
  page: number; 
  limit: number;
}

export class ApiQueryFilter {
	op: QueryFilterOperator | QueryFilterOperator[];
	field: string;
	value: string | number | boolean | string[] | number[] | boolean[] | null;

	constructor(input: {
		op: QueryFilterOperator | QueryFilterOperator[];
		field: string;
		value: string | number | boolean | string[] | number[] | boolean[] | null;
	}) {
		this.op = input.op;
		this.field = input.field;
		this.value = input.value;
	}

	getOpString(): string {
		if (Array.isArray(this.op)) {
			return this.op.join(':');
		}
		return this.op;
	}
}

export class ApiQueryParam {
	op: string;
	field: string;
	value: string | number | boolean | string[] | number[] | boolean[] | null;

	constructor(input: {
		op?: string;
		field: string;
		value: string | number | boolean | string[] | number[] | boolean[] | null;
	}) {
		this.op = input.op || '=';
		this.field = input.field;
		this.value = input.value;
	}
}

export class ApiQuerySort {
	field: string;
	dir: 'asc' | 'desc';

	constructor(input: { field: string; dir: 'asc' | 'desc' }) {
		if (input) {
			this.field = input.field;
			this.dir = input.dir;
		}
	}
}

export class ApiQueryParams {
	private _param: ApiQueryParam[] = [];
	private _filter: ApiQueryFilter[] = [];
	private _sort: ApiQuerySort[] = [];

	length?: number;
	limit: number | null;
	page: number | null;
	search: string | null;

	constructor(partial?: Partial<ApiQueryParams>) {
		this.page = 0;
		this.limit = DEFAULT_PAGE_LIMIT;
		Object.assign(this, partial);
	}

	get filter(): ApiQueryFilter | ApiQueryFilter[] {
		if (this._filter.length > 1) {
			return this._filter;
		}
		return this._filter.slice(0, 1)[0];
	}

	set filter(value: ApiQueryFilter | ApiQueryFilter[]) {
		if (!Array.isArray(value)) {
			this._filter = [value];
			return;
		}
		this._filter = value;
	}

	get param(): ApiQueryParam | ApiQueryParam[] {
		if (this._param.length > 1) {
			return this._param;
		}
		return this._param.slice(0, 1)[0];
	}

	set param(value: ApiQueryParam | ApiQueryParam[]) {
		if (!Array.isArray(value)) {
			this._param = [value];
			return;
		}
		this._param = value;
	}

	get sort(): ApiQuerySort | ApiQuerySort[] {
		if (this._sort.length > 1) {
			return this._sort;
		}
		return this._sort.slice(0, 1)[0];
	}

	set sort(value: ApiQuerySort | ApiQuerySort[]) {
		if (!Array.isArray(value)) {
			this._sort = [value];
			return;
		}
		this._sort = value;
	}

	addFilter(value: ApiQueryFilter): void {
		const findedFilter = this._filter.find(item => item.field === value.field);
		if (findedFilter) {
			this._filter[this._filter.indexOf(findedFilter)] = value;
			return;
		}
		this._filter.push(value);
	}

	removeFilter(field: string): void {
		this._filter = this._filter.filter(item => item.field !== field);
	}

	filterOf(field: string): ApiQueryFilter | ApiQueryFilter[] {
		return this._filter.find(item => item.field === field);
	}

	addParam(value: ApiQueryParam): void {
		const findedParam = this._param.find(item => item.field === value.field);
		if (findedParam) {
			this._param[this._param.indexOf(findedParam)] = value;
			return;
		}
		this._param.push(value);
	}

	removeParam(field: string): void {
		this._param = this._param.filter(item => item.field !== field);
	}

	addSort(value: ApiQuerySort): void {
		const findedSort = this._sort.find(item => item.field === value.field);
		if (findedSort) {
			this._sort[this._sort.indexOf(findedSort)] = value;
			return;
		}
		this._sort.push(value);
	}

	toQuery(): string {
		const query = [];
		if (this.limit !== undefined) {
			query.push(`limit=${this.limit}`);
		}

		if (this.page !== undefined) {
			query.push(`page=${this.page}`);
		}

		if (this.search) {
			query.push(`search=${this.search}`);
		}

		if (this._sort.length > 0) {
			const vSort = this.sort;
			if (Array.isArray(vSort)) {
				query.push('sortBy[]=' + vSort.map(s => `${s.field}:${s.dir.toUpperCase()}`).join(','));
			} else {
				query.push(`sortBy=${vSort.field}:${vSort.dir.toUpperCase()}`);
			}
		}

		if (this._filter.length > 0) {
			const vFilter = this.filter;
			if (Array.isArray(vFilter)) {
				vFilter.forEach(fil => {
					query.push(`filter.${fil.field}=${fil.getOpString()}:${fil.value}`);
				}, query);
			} else {
				query.push(`filter.${vFilter.field}=${vFilter.getOpString()}:${vFilter.value}`);
			}
		}
		if (this._param.length > 0) {
			const vParam = this.param;
			if (Array.isArray(vParam)) {
				vParam.forEach(par => {
					query.push(`${par.field}${par.op}${par.value}`);
				}, query);
			} else {
				query.push(`${vParam.field}${vParam.op}${vParam.value}`);
			}
		}
		return '?' + query.join('&');
	}
}

import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule()
export class IconsModule {
	/**
	 * Constructor
	 */
	constructor(
		private _domSanitizer: DomSanitizer,
		private _matIconRegistry: MatIconRegistry,
	) {
		// Register icon sets
		this._matIconRegistry.addSvgIconSet(
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-twotone.svg'),
		);
		this._matIconRegistry.addSvgIconSetInNamespace(
			'mat_outline',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg'),
		);
		this._matIconRegistry.addSvgIconSetInNamespace(
			'mat_solid',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-solid.svg'),
		);
		this._matIconRegistry.addSvgIconSetInNamespace(
			'iconsmind',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/iconsmind.svg'),
		);
		this._matIconRegistry.addSvgIconSetInNamespace(
			'icon-chemical-blue',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-chemical-blue.svg'),
		);
		this._matIconRegistry.addSvgIconSetInNamespace(
			'feather',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/feather.svg'),
		);
		this._matIconRegistry.addSvgIconSetInNamespace(
			'heroicons_outline',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-outline.svg'),
		);
		this._matIconRegistry.addSvgIconSetInNamespace(
			'heroicons_solid',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-solid.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-silo',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-silo.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-silos-panel',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-silos-panel.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-silos-total',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-silos-total.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-bulk-total',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-bulk-total.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-stock',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-stock.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-grain-analysis',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-grain-analysis.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-info-panel',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-info-panel.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-grain-price',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-grain-price.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-patrimony',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-patrimony.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-show-patrimony',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-show-patrimony.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-not-show-patrimony',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-not-show-patrimony.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-dryer',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-dryer.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'aerator-icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/aerator-icon.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-temperature',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-temperature.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-archive',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-archive.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-plus',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-plus.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-save',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-save.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-send-now',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-send-now.svg'),
		);
		/// STORAGE ///
		this._matIconRegistry.addSvgIcon(
			'icon-storage-blue',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-storage-blue.svg'),
		);
		// Panel //
		this._matIconRegistry.addSvgIcon(
			'icon-panel',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-panel.svg'),
		);
		// Analyst activity //
		this._matIconRegistry.addSvgIcon(
			'icon-analyst',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-analyst.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// General information //
			'icon-infos',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-infos.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Check-in/Check-out //
			'icon-checkin',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-checkin.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Automatic Email //
			'icon-email',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-email.svg'),
		);
		// Monitoring //
		this._matIconRegistry.addSvgIcon(
			'icon-monitoring',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-monitoring.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Flagged Silos/Warehouses //
			'icon-flagged-warehouses',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-flagged-warehouses.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Technical problems //
			'icon-technical-problems',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-technical-problems.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Drying Problems //
			'icon-drying-problems',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-drying-problems.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Storage Problems //
			'icon-silo',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-silo.svg'),
		);
		// Registration //
		this._matIconRegistry.addSvgIcon(
			'icon-registration',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-registration.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Silos //
			'icon-silo',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-silo.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Warehouses //
			'icon-warehouse2',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-warehouse2.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Warehouses Aerators //
			'icon-aerator-storage',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-aerator-storage.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Sampling //
			'icon-samplings',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-samplings.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Equipment //
			'icon-equipment2',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-equipment2.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Local Weather Station //
			'icon-meteorology-local',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-meteorology-local.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Remote Weather Station //
			'icon-meteorology-remote',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-meteorology-remote.svg'),
		);
		// Settings //
		this._matIconRegistry.addSvgIcon(
			'icon-config',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-config.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Rules //
			'icon-rules',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-rules.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Programs //
			'icon-program',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-program.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Power management //
			'icon-energy',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-energy.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Trigger Group //
			'icon-trigger-group',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-trigger-group.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Heating variables //
			'icon-heating-variables',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-heating-variables.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Heating variables //
			'icon-heating-variables-blue',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-heating-variables-blue.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Auxiliary Readers //
			'icon-auxiliary-readers',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-auxiliary-readers.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Storage Units //
			'icon-storage-unit',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-storage-unit.svg'),
		);
		/// DRYER ///
		this._matIconRegistry.addSvgIcon(
			'icon-dryer-blue',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-dryer-blue.svg'),
		);
		// Monitoring //
		this._matIconRegistry.addSvgIcon(
			'icon-monitoring',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-monitoring.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Technical problems //
			'icon-technical-problems',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-technical-problems.svg'),
		);
		// Registration //
		this._matIconRegistry.addSvgIcon(
			'icon-registration',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-registration.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Dryers //
			'icon-email',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-email.svg'),
		);
		/// UNIT MANAGEMENT ///
		this._matIconRegistry.addSvgIcon(
			'icon-management-blue',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-management-blue.svg'),
		);
		// Settings //
		this._matIconRegistry.addSvgIcon(
			'icon-config',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-config.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Procer server //
			'icon-checkin',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-checkin.svg'),
		);
		/// REGISTRATIONS ///
		this._matIconRegistry.addSvgIcon(
			'icon-registration-blue',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-registration-blue.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Customers //
			'icon-customer',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-customer.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Storage Units //
			'icon-warehouse',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-warehouse.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Users //
			'icon-users',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-users.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Manufacturers //
			'icon-manufacturers',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-manufacturers.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Equipment //
			'icon-equipment',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-equipment.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Farm Products //
			'icon-agricultural',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-agricultural.svg'),
		);
		/// SETTINGS ///
		this._matIconRegistry.addSvgIcon(
			'icon-configs-blue',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-configs-blue.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Alerts //
			'icon-alerts',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-alerts.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Alerts //
			'icon-alerts-blue',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-alerts-blue.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-arrow-left-email',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-arrow-left-email.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-recebido-email',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-recebido-email.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-lido-email',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-lido-email.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-rejeitado-email',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-rejeitado-email.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-stopwatch',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-cronometro.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-stopwatch-out',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-stopwatch-out.svg'),
		);

		this._matIconRegistry.addSvgIcon(
			'icon-pause-blue',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-pause-blue.svg'),
		);

		this._matIconRegistry.addSvgIcon(
			'icon-pause',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-pause.svg'),
		);

		this._matIconRegistry.addSvgIcon(
			'icon-fan-blue',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-fan-blue.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'helper-icon',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/helper-icon.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-checklist',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-checklist.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-country',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-country.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-silos',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-silos.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-silos-blue',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-silos-blue.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-silos-bold',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-silos-bold.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-contract',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-contract.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-transceiver',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-transceiver.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-sensor',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-sensor.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-pressao',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-pressao.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-manufacturer',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-manufacturer.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-verified',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-verified.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-analyzer',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-analyzer.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-eraser',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-eraser.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-equipment-analyzer',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-equipment-analyzer.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-weather-station',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-weather-station.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-dump',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-dump.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-duplicate',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-duplicate.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-thermometer-white',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-thermometer-white.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-pressure-white',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-pressure-white.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-moisture-grain-white',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-moisture-grain-white.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-ur-while',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-ur-white.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-meteorology-neighboring',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-meteorology-neighboring.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-priority-order',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-priority-order.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			// Troca de Pêndulo //
			'icon-change-pendulum',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-change-pendulum.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-communication-alert',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-communication-alert.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-not-show',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-not-show.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'icon-show',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-show.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'previous-clock',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/previous-clock.svg'),
		);
		this._matIconRegistry.addSvgIcon(
			'desfazer',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/icon-desfazer.svg'),
		);

		// Ícones de grãos
		this._matIconRegistry.addSvgIcon(
			'arroz',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/graos/arroz_em_casca.svg'),
		);

		this._matIconRegistry.addSvgIcon(
			'soja',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/graos/soja.svg'),
		);

		this._matIconRegistry.addSvgIcon(
			'milho',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/graos/milho.svg'),
		);

		this._matIconRegistry.addSvgIcon(
			'trigo',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/graos/trigo.svg'),
		);

		this._matIconRegistry.addSvgIcon(
			'aveia',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/graos/aveia.svg'),
		);

		this._matIconRegistry.addSvgIcon(
			'canola',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/graos/canola.svg'),
		);

		this._matIconRegistry.addSvgIcon(
			'feijao',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/graos/feijao.svg'),
		);

		this._matIconRegistry.addSvgIcon(
			'cevada',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/graos/cevada.svg'),
		);

		this._matIconRegistry.addSvgIcon(
			'sorgo',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/graos/sorgo.svg'),
		);

		this._matIconRegistry.addSvgIcon(
			'milho_pipoca',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/graos/milho_pipoca.svg'),
		);

		this._matIconRegistry.addSvgIcon(
			'milheto',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/graos/milheto.svg'),
		);

		this._matIconRegistry.addSvgIcon(
			'girassol',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/graos/girassol.svg'),
		);

		this._matIconRegistry.addSvgIcon(
			'triticale',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/graos/triticale.svg'),
		);

		this._matIconRegistry.addSvgIcon(
			'caroco_algodao',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/graos/caroco_de_algodao.svg'),
		);

		this._matIconRegistry.addSvgIcon(
			'nabo_forrageiro',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/graos/nabo_forrageiro.svg'),
		);

		this._matIconRegistry.addSvgIcon(
			'painco',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/graos/painco.svg'),
		);

		this._matIconRegistry.addSvgIcon(
			'cafe_descascado',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/graos/cafe_descascado.svg'),
		);

		this._matIconRegistry.addSvgIcon(
			'brachiaria',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/graos/brachiaria.svg'),
		);

		this._matIconRegistry.addSvgIcon(
			'gergelim',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/graos/gergelim.svg'),
		);

		this._matIconRegistry.addSvgIcon(
			'ervilha',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/graos/ervilha.svg'),
		);

		this._matIconRegistry.addSvgIcon(
			'cafe_pergaminho',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/graos/cafe_em_pergaminho.svg'),
		);

		this._matIconRegistry.addSvgIcon(
			'azevem',
			this._domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/graos/azevem.svg'),
		);
	}
}

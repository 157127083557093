import { Injectable } from '@angular/core';
import { IHttpGateway } from '@botools/infra';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable, catchError, of, tap } from 'rxjs';
import BaseEntity from '../entity/base.entity';
import { ApiPaginatedResponse } from '../model/api-paginated-response.model';
import { ApiQueryParams } from '../model/api-query.model';
import { AlertService } from './alert.service';

export type DefaultApiMessages = {
	getError?: string;
	getSuccess?: string;
	getPaginatedError?: string;
	getPaginatedSuccess?: string;
	postSuccess?: string;
	postError?: string;
	postFileSuccess?: string;
	postFileError?: string;
	putSuccess?: string;
	putError?: string;
	patchSuccess?: string;
	patchError?: string;
	deleteSuccess?: string;
	deleteError?: string;
	archiveSuccess?: string;
	archiveError?: string;
	unarchiveSuccess?: string;
	unarchiveError?: string;
};

@Injectable()
export abstract class ApiService<E extends BaseEntity> {
	protected host: string;
	protected messages: DefaultApiMessages;
	protected postUrl: string = null;
	protected postFilesUrl: string = null;
	protected putUrl: string = null;
	protected patchUrl: string = null;
	protected getByIdUrl: string = null;
	protected getUrl: string = null;
	protected archiveUrl: string = null;
	protected unarchiveUrl: string = null;
	protected limit: number = null;

	constructor(
		protected http: IHttpGateway,
		protected _alert: AlertService,
		protected translate: TranslocoService,
	) {}

	get(query: ApiQueryParams): Observable<E[]> {
		const host = this.getUrl || this.host;
		query.limit = this.limit || query.limit;
		return this.http.get<E[]>(`${host}${query.toQuery()}`).pipe(
			catchError((error: any) => of(error)),
			tap((res: any) => {
				if (res.error) {
					this.handleError(res, this.messages.getError);
					return;
				}
				if (this.messages.getSuccess) {
					this._alert.showSuccess(this.messages.getSuccess);
				}
			}),
		);
	}

	getById(id: number | string): Observable<E> {
		const host = this.getByIdUrl || this.host;
		return this.http.get<E>(`${host}/${id}`).pipe(
			catchError((error: any) => of(error)),
			tap((res: any) => {
				if (res.error) {
					this.handleError(res, this.messages.getError);
					return;
				}
				if (this.messages.getSuccess) {
					this._alert.showSuccess(this.messages.getSuccess);
				}
			}),
		);
	}

	getManyById(id: number | string): Observable<E[]> {
		const host = this.getByIdUrl || this.host;
		return this.http.get<E[]>(`${host}/${id}`).pipe(
			catchError((error: any) => of(error)),
			tap((res: any) => {
				if (res.error) {
					this.handleError(res, this.messages.getError);
					return;
				}
				if (this.messages.getSuccess) {
					this._alert.showSuccess(this.messages.getSuccess);
				}
			}),
		);
	}

	post(data: E): Observable<E> {
		const host = this.postUrl || this.host;
		if (data.id !== undefined && !data.id) {
			delete data.id;
		}
		return this.http.post<E>(host, data.toRequest()).pipe(
			catchError((error: any) => of(error)),
			tap((res: any) => {
				if (res.error) {
					this.handleError(res, this.messages.postError);
					return;
				}
				if (this.messages.postSuccess) {
					this._alert.showSuccess(this.messages.postSuccess);
				}
			}),
		);
	}

	put(id: number | string, data: E): Observable<E> {
		const host = this.putUrl || this.host;
		return this.http.put<E>(`${host}/${id}`, data.toRequest()).pipe(
			catchError((error: any) => of(error)),
			tap((res: any) => {
				if (res.error) {
					this.handleError(res, this.messages.putError);
					return;
				}
				if (this.messages.putSuccess) {
					this._alert.showSuccess(this.messages.putSuccess);
				}
			}),
		);
	}

	patch(id: number | string, data: Partial<E>): Observable<E> {
		const host = this.patchUrl || `${this.host}`;
		return this.http.patch<E>(`${host}/${id}`, data.toRequest()).pipe(
			catchError((error: any) => of(error)),
			tap((res: any) => {
				if (res.error) {
					this.handleError(res, this.messages.patchError);
					return;
				}
				if (this.messages.patchSuccess) {
					this._alert.showSuccess(this.messages.patchSuccess);
				}
			}),
		);
	}

	delete(id: number | string): Observable<E> {
		return this.http.delete<E>(`${this.host}/${id}`).pipe(
			catchError((error: any) => of(error)),
			tap((res: any) => {
				if (res.error) {
					this.handleError(res, this.messages.patchError);
					return;
				}
				if (this.messages.patchSuccess) {
					this._alert.showSuccess(this.messages.patchSuccess);
				}
			}),
		);
	}

	archive(id: number | string): Observable<E> {
		const host = this.archiveUrl || `${this.host}/arquivar`;
		return this.http.patch(`${host}/${id}`, {}).pipe(
			catchError((error: any) => of(error)),
			tap((res: any) => {
				if (res.error) {
					this.handleError(res, this.messages.archiveError);
					return;
				}
				if (this.messages.archiveSuccess) {
					this._alert.showSuccess(this.messages.archiveSuccess);
				}
			}),
		);
	}

	unarchive(id: number | string): Observable<E> {
		const host = this.unarchiveUrl || `${this.host}/desarquivar`;
		return this.http.patch(`${host}/${id}`, {}).pipe(
			catchError((error: any) => of(error)),
			tap((res: any) => {
				if (res.error) {
					this.handleError(res, this.messages.unarchiveError);
					return;
				}
				if (this.messages.unarchiveSuccess) {
					this._alert.showSuccess(this.messages.unarchiveSuccess);
				}
			}),
		);
	}

	postFiles(id: number | string, files: File[], formDataField: string = 'arquivo'): Observable<any> {
		const host = this.postFilesUrl || `${this.host}`;

		const formData = new FormData();
		files.forEach((file: File) => {
			formData.append(formDataField, file, file.name);
		});
		return this.http.post<any>(`${host}/${id}`, formData).pipe(
			catchError((error: any) => of(error)),
			tap((res: any) => {
				if (res.error) {
					this.handleError(res, this.messages.postFileError);
					return;
				}
				if (this.messages.postFileSuccess) {
					this._alert.showSuccess(this.messages.postFileSuccess);
				}
			}),
		);
	}

	protected handleError(res: any, defaultMessage: string): void {
		if (defaultMessage) {
			this._alert.showError(defaultMessage, res?.error?.error?.message || res?.error?.message || res?.message);
		}
	}
}

@Injectable()
export abstract class ApiPaginatedService<E extends BaseEntity> extends ApiService<E> {
	protected paginateUrl = null;

	private _data: BehaviorSubject<BaseEntity[] | null> = new BehaviorSubject(null);

	get data$(): Observable<BaseEntity[]> {
		return this._data.asObservable();
	}
	getPaginated(query: ApiQueryParams): Observable<ApiPaginatedResponse<E>> {
		const host = this.paginateUrl || this.host;
		query.limit = this.limit || query.limit;
		return this.http.get<ApiPaginatedResponse<E>>(`${host}${query.toQuery()}`).pipe(
			catchError((error: any) => of(error)),
			tap((res: any) => {
				if (res.error) {
					this.handleError(res, this.messages.getPaginatedError);
					return;
				}
				this._data.next(res.data);
				if (this.messages.getPaginatedSuccess) {
					this._alert.showSuccess(this.messages.getPaginatedSuccess);
				}
			}),
		);
	}

	getPaginatedById(query: ApiQueryParams, id: number | string): Observable<ApiPaginatedResponse<E>> {
		const host = this.paginateUrl || this.host;
		query.limit = this.limit || query.limit;
		return this.http.get<ApiPaginatedResponse<E>>(`${host}/${id}${query.toQuery()}`).pipe(
			catchError((error: any) => of(error)),
			tap((res: any) => {
				if (res.error) {
					this.handleError(res, this.messages.getPaginatedError);
					return;
				}
				this._data.next(res.data);
				if (this.messages.getPaginatedSuccess) {
					this._alert.showSuccess(this.messages.getPaginatedSuccess);
				}
			}),
		);
	}
}

import { Injectable } from '@angular/core';
import { HttpBaseService } from 'app/core/base/services/http-base.service';

@Injectable()
export default class MovimentoEstoqueService extends HttpBaseService {
	constructor() {
		super({
			url: 'pendulo-nivel-sensor/resumo-unidade/movimento-estoque',
		});
	}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen/fullscreen.component';

@NgModule({
	imports: [FuseFullscreenComponent, MatButtonModule, MatIconModule, MatTooltipModule, CommonModule],
	exports: [FuseFullscreenComponent],
})
export class FuseFullscreenModule {}

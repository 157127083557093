import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AlertService } from '@botools/shared';
import { FuseDrawerModule } from '@fuse/components/drawer/drawer.module';
import { TranslocoModule } from '@ngneat/transloco';
import { take } from 'rxjs';
import { IEstruturaArmazenagemInfo } from './interfaces/estrutura-armazenagem-info.interface';
import { IResumoUnidade } from './interfaces/resumo-unidade.interface';
import FalhasAcionamentoService from './services/falhas-acionamento.service';
import MovimentoEstoqueService from './services/movimento-estoque.service';
import ResumoUnidadeService from './services/resumo-unidade.service';

@Component({
	selector: 'unit-summary',
	standalone: true,
	imports: [CommonModule, FuseDrawerModule, MatIconModule, MatTooltipModule, TranslocoModule],
	templateUrl: './unit-summary.component.html',
	styleUrls: ['./unit-summary.component.scss'],
	providers: [ResumoUnidadeService, MovimentoEstoqueService, FalhasAcionamentoService],
})
export class UnitSummaryComponent implements OnInit {
	private resumoUnidadeService = inject(ResumoUnidadeService);
	private movimentoEstoqueService = inject(MovimentoEstoqueService);
	private falhasAcionamentoService = inject(FalhasAcionamentoService);
	protected alert = inject(AlertService);

	public resumoUnidade: IResumoUnidade;
	public movimentoEstoque: IEstruturaArmazenagemInfo[];
	public falhasAcionamento: IEstruturaArmazenagemInfo[];

	constructor() {}

	ngOnInit(): void {}

	public getUnitSummary(): void {
		this.getFalhasAcionamento();
		this.getMovimentoEstoque();
		this.getResumoUnidade();
	}

	private getFalhasAcionamento(): void {
		this.falhasAcionamentoService
			.get<IEstruturaArmazenagemInfo>()
			.pipe(take(1))
			.subscribe({
				next: res => {
					this.falhasAcionamento = res;
				},
				error: (err: any) => {
					this.alert.showError('', 'Erro ao buscar falhas de acionamento');
				},
			});
	}

	private getMovimentoEstoque(): void {
		this.movimentoEstoqueService
			.get<IEstruturaArmazenagemInfo>()
			.pipe(take(1))
			.subscribe({
				next: res => {
					this.movimentoEstoque = res;
				},
				error: (err: any) => {
					this.alert.showError('', 'Erro ao buscar movimento do estoque');
				},
			});
	}

	private getResumoUnidade(): void {
		this.resumoUnidadeService
			.getResumoUnidade<IResumoUnidade>()
			.pipe(take(1))
			.subscribe({
				next: res => {
					this.resumoUnidade = res;
				},
				error: (err: any) => {
					this.alert.showError('', 'Erro ao buscar resumo unidade');
				},
			});
	}
}

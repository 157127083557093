import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { HttpBaseService } from 'app/core/base/services/http-base.service';
import { ApiQueryFilter, ApiQueryParams, QueryFilterOperator } from 'app/shared/model/api-query.model';
import { ReplaySubject, Subject, catchError, debounceTime, delay, take, takeUntil, tap } from 'rxjs';

@Component({
	selector: 'app-custom-search',
	templateUrl: './custom-search.component.html',
})
export class CustomSearchComponent implements OnInit, OnChanges, OnDestroy {
	@Input() public label: string;
	@Input() public controlName: string;
	@Input() public formGroup: FormGroup;
	@Input() public showNone = false;
	@Input() public valueNone: any = null;
	@Input() public customService: HttpBaseService = null;
	@Input() public customFilter: ApiQueryFilter | ApiQueryFilter[] = null;
	@Input() public transformData: any = null;
	@Input() public value: any = null;
	@Input() public skipId: string | number;
	@Input() public filterField: string;
	@Input() public filterControl: string;
	@Input() public idField: string = 'id';
	@Output() change = new EventEmitter<any>();

	@ViewChild('singleSelectData') singleSelectData: MatSelect;

	public defaultClass = 'flex-auto gt-xs:pr-3';
	public dataServerSideFilteringCtrl: FormControl = new FormControl();
	public searching: boolean = false;
	public filteredServerSideData: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

	private filter: ApiQueryFilter = null;
	private initialValue: any = null;
	private lastInitialValue: any = null;
	private filterControlValue: any = null;
	private queryParams = new ApiQueryParams();
	private initialized = false;
	private loading = true;
	private _unsubscribeAll: Subject<any> = new Subject<any>();

	constructor() {}

	ngOnInit(): void {
		this.initializeComponent();
		this.setupValueChangeSubscriptions();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!this.initialized) {
			this.handleInitialChanges(changes);
		}
		this.initializeComponent();
		this.setupValueChangeSubscriptions();
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	private initializeComponent(): void {
		this.transformData = this.transformData || this.transformDataDefault;
		this.setupQueryParams();
		this.fetchInitialData();
		this.setupSearchAction();
	}

	private setupQueryParams(): void {
		this.addCustomFilters();
		this.addNonArchivedFilter();
	}

	private addCustomFilters(): void {
		if (Array.isArray(this.customFilter)) {
			this.customFilter.forEach(f => this.queryParams.addFilter(f));
		} else if (this.customFilter) {
			this.queryParams.addFilter(this.customFilter);
		}
	}

	private addNonArchivedFilter(): void {
		this.queryParams.addFilter(
			new ApiQueryFilter({
				op: QueryFilterOperator.EQ,
				field: 'arquivado',
				value: false,
			}),
		);
	}

	private fetchInitialData(): void {
		if (!this.filterField && !this.filterControl) {
			this.fetchData();
		}
	}

	private setupSearchAction(): void {
		this.dataServerSideFilteringCtrl.valueChanges
			.pipe(
				debounceTime(200),
				delay(500),
				tap(() => (this.searching = false)),
				takeUntil(this._unsubscribeAll),
				catchError(error => {
					this.searching = false;
					return [];
				}),
			)
			.subscribe(search => this.searchAction(search));
	}

	private handleInitialChanges(changes: SimpleChanges): void {
		if (this.filterControl && this.formGroup.get(this.filterControl).value) {
			const filterValue = this.formGroup.get(this.filterControl).value;
			if (filterValue) {
				if (this.formGroup.get(this.controlName).value) {
					this.initialValue = this.formGroup.get(this.controlName).value;
				}
				if (!this.filter) {
					this.filter = new ApiQueryFilter({
						op: QueryFilterOperator.EQ,
						field: this.filterField,
						value: null,
					});
				}
				this.filter.value = filterValue[this.idField];
				this.dataServerSideFilteringCtrl.reset();
				this.queryParams.addFilter(this.filter);
				this.initialized = true;
				this.searchAction();
			}
		}
		if (changes?.value?.firstChange) {
			this.initialValue = changes.value.currentValue;
			if (!this.loading) {
				this.searchAction();
			}
			this.loading = false;
		}
	}

	private setupValueChangeSubscriptions(): void {
		if (this.filterField && this.filterControl) {
			this.formGroup
				.get(this.filterControl)
				.valueChanges.pipe(takeUntil(this._unsubscribeAll))
				.subscribe(value => {
					if (value) {
						if (!this.filter) {
							this.filter = new ApiQueryFilter({
								op: QueryFilterOperator.EQ,
								field: this.filterField,
								value: null,
							});
						}
						this.filter.value = value[this.idField];
						this.dataServerSideFilteringCtrl.reset();
						this.queryParams.addFilter(this.filter);
						this.searchAction();
					}
				});
		}
		if (this.formGroup.get(this.controlName).value) {
			this.initialValue = this.formGroup.get(this.controlName).value;
			this.lastInitialValue = this.initialValue;
		}

		if (this.change) {
			this.formGroup
				.get(this.controlName)
				.valueChanges.pipe(takeUntil(this._unsubscribeAll))
				.subscribe((value: any) => {
					this.change.emit(value);
				});
		}
	}

	private fetchData(search = null): void {
		this.queryParams.search = search;
		this.updateFilter();
		this.customService
			.getPaginated<any>(this.queryParams)
			.pipe(
				take(1),
				catchError(error => {
					return [];
				}),
				takeUntil(this._unsubscribeAll),
			)
			.subscribe(data => this.handleData(data));
	}

	private updateFilter(): void {
		if (this.filter) {
			this.queryParams.removeFilter(this.filter.field);
			if (this.filter.value) {
				this.queryParams.addFilter(this.filter);
			}
		}
	}

	private handleData(dataReturn: any): void {
		let items = dataReturn.data;
		if (!items) {
			return;
		}
		items = items.filter(value => value?.[this.idField] != this.skipId);

		if (this.initialValue && !items.some(item => item[this.idField] === this.initialValue[this.idField])) {
			items.unshift(this.initialValue);
		}
		if (
			this.filterField &&
			this.filterControl &&
			this.filterControlValue !== this.formGroup.get(this.filterControl).value &&
			items.indexOf(this.lastInitialValue) >= 0
		) {
			items = items.filter(item => item[this.idField] !== this.lastInitialValue[this.idField]);
			this.filterControlValue = null;
			this.lastInitialValue = null;
		}

		this.filteredServerSideData.next(items);
		this.searching = false;
		this.setInitialValue();
	}

	private setInitialValue(): void {
		if (!this.singleSelectData) {
			return;
		}
		this.filteredServerSideData.pipe(take(1), takeUntil(this._unsubscribeAll)).subscribe(value => {
			this.singleSelectData.compareWith = (a: any, b: any) =>
				a?.[this.idField] && b?.[this.idField] && a[this.idField] === b[this.idField];
		});
	}

	private transformDataDefault(data: any): string {
		return data.nome || data.name;
	}

	private searchAction(search: string = null): void {
		this.searching = true;
		this.fetchData(search);
	}
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LoaderService {
	loaderSubject = new Subject<boolean>();

	showLoader(): void {
		this.loaderSubject.next(true);
	}

	hideLoader(): void {
		this.loaderSubject.next(false);
	}
}

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, filter, switchMap, take, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { AuthUtils } from './auth.utils';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private isRefreshing = false;
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	//    private _unsubscribeAll: Subject<any> = new Subject<any>();

	/**
	 * Constructor
	 */
	constructor(private _authService: AuthService) {}

	/**
	 * Intercept
	 *
	 * @param req
	 * @param next
	 */
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// Clone the request object
		let newReq = req.clone();

		// Request
		//
		// If the access token didn't expire, add the Authorization header.
		// We won't add the Authorization header if the access token expired.
		// This will force the server to return a "401 Unauthorized" response
		// for the protected API routes which our response interceptor will
		// catch and delete the access token from the local storage while logging
		// the user out from the app.
		if (this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken)) {
			newReq = this.addTokenHeader(req, this._authService.accessToken);
		} else {
			newReq = this.addBranchHeader(req);
		}

		// Response
		return next.handle(newReq).pipe(
			catchError(error => {
				// Catch "401 Unauthorized" responses
				if (error instanceof HttpErrorResponse && !newReq.url.includes('sign-in') && error.status === 401) {
					return this.handle401Error(newReq, next);
				} else {
					return throwError(error);
				}
			}),
		);
	}

	private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
		if (!this.isRefreshing) {
			this.isRefreshing = true;
			this.refreshTokenSubject.next(null);
			if (this._authService.accessToken) {
				return this._authService.signInUsingToken().pipe(
					switchMap((tokenRefresh: any) => {
						this.isRefreshing = false;
						this.refreshTokenSubject.next(tokenRefresh);
						return next.handle(this.addTokenHeader(request, tokenRefresh));
					}),
					catchError(err => {
						this.isRefreshing = false;

						//Sign out
						this._authService.signOut();

						//Reload the app
						location.reload();

						return throwError(err);
					}),
				);
			}
		}
		return this.refreshTokenSubject.pipe(
			filter((token: any) => token !== null),
			take(1),
			switchMap((token: any) => next.handle(this.addTokenHeader(request, token))),
		);
	}

	private addTokenHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
		let customHeaders = request.headers.set('Authorization', 'Bearer ' + token);

		if (this._authService.branchId) {
			customHeaders = request.headers
				.set('Authorization', 'Bearer ' + token)
				.set('x-unidade-id', this._authService.branchId);
		}
		return request.clone({
			headers: customHeaders,
		});
	}

	private addBranchHeader(request: HttpRequest<any>): HttpRequest<any> {
		if (this._authService.branchId) {
			const customHeaders = request.headers.set('x-unidade-id', this._authService.branchId);
			return request.clone({
				headers: customHeaders,
			});
		}
		return request;
	}
}

import { Pipe, PipeTransform } from '@angular/core';
import { StringFormatHelper } from '../helpers/string-format.helper';

@Pipe({
	name: 'FormatFileSize',
})
export class FormatFileSizePipe implements PipeTransform {
	transform(value: number): string {
		return StringFormatHelper.formatFileSize(value);
	}
}

import { Component, OnInit } from '@angular/core';
import { FileSystemDirectoryEntry, FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { take } from 'rxjs';
import { FileUploadService } from './service/file-upload.service';

@Component({
	selector: 'app-file-upload',
	templateUrl: './file-upload.component.html',
	styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
	files: File[] = [];

	constructor(private fileUploadService: FileUploadService) {}

	ngOnInit(): void {}

	dropped(files: NgxFileDropEntry[]): void {
		for (const droppedFile of files) {
			if (droppedFile.fileEntry.isFile) {
				const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
				fileEntry.file((file: File) => {
					this.files.push(file);
				});
			} else {
				const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
			}
		}
	}

	removeFile(indexFile): void {
		this.files.splice(indexFile, 1);
	}

	bytesToMegabytes(bytes: number): string {
		let valueInMegabytes = 0;
		valueInMegabytes = bytes / (1024 * 1024);
		return `${valueInMegabytes.toFixed(2)} MB`;
	}

	saveFiles(constractId: number, filialId): void {
		this.fileUploadService.uploadFile(this.files, constractId, filialId).pipe(take(1)).subscribe();
	}
}

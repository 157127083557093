import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { IHttpGateway } from '../../infra/gateway/HttpGateway.interface';
import HttpClientAdapter from '../../infra/http/HttpClient.adapter';
import { CountryPhoneComponent } from './components/country-phone/country-phone.component';
import { CustomFileComponent } from './components/custom-file/custom-file.component';
import { CustomSearchComponent } from './components/custom-search/custom-search.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { DecimalInputDirective } from './directives/decimal-input.directive';
import { IntegerInputDirective } from './directives/integer-input.directive';
import { FormatDateHourPipe } from './pipes/format-date-hour.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { FormatFileSizePipe } from './pipes/format-file-size.pipe';
import { ArchiveService } from './services/archive.service';
import { DeleteService } from './services/delete.service';
@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		NgxFileDropModule,
		MatIconModule,
		TranslocoModule,
		MatFormFieldModule,
		MatSelectModule,
		MatCheckboxModule,
		NgxMatSelectSearchModule,
		MatInputModule,
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		FileUploadComponent,
		CustomSearchComponent,
		CustomFileComponent,
		CountryPhoneComponent,
		FormatDatePipe,
		FormatFileSizePipe,
		FormatDateHourPipe,
		DecimalInputDirective,
		IntegerInputDirective,
	],
	declarations: [
		FileUploadComponent,
		CustomSearchComponent,
		CustomFileComponent,
		CountryPhoneComponent,
		FormatDatePipe,
		FormatFileSizePipe,
		FormatDateHourPipe,
		DecimalInputDirective,
		IntegerInputDirective,
	],
	providers: [
		FileUploadComponent,
		ArchiveService,
		DeleteService,
		{
			provide: IHttpGateway,
			useClass: HttpClientAdapter,
		},
	],
})
export class SharedModule {}

<ng-container *ngIf="user">
	<div class="flex items-center text-center justify-center gap-2">
		<button mat-button [matMenuTriggerFor]="checkinOrCheckoutMenu">
			<div class="relative flex flex-0 items-center cursor-pointer" mat-icon-button>
				<div *ngIf="isModeCheckIn()" class="flex items-center text-[#019DE8] w-[65px]">
					<mat-icon
						*ngIf="isModeCheckIn()"
						class="w-5 mr-2"
						mat-icon-button
						[svgIcon]="'icon-stopwatch-out'"
					></mat-icon>
					<cd-timer
						#checkInTimer
						*ngIf="isModeCheckIn()"
						format="{{ formatTimer }}"
						[startTime]="setStartTime"
					></cd-timer>
				</div>
				<mat-icon
					*ngIf="!isModeCheckIn()"
					class="w-5"
					mat-icon-button
					[svgIcon]="'heroicons_outline:logout'"
				></mat-icon>
				<span *ngIf="!isModeCheckIn()">
					{{ 'MODULES.ADMIN.CHECK_IN_OR_CHECK_OUT.CHECK_IN_LABEL' | transloco }}
				</span>
			</div>
		</button>
	</div>
	<mat-menu xPosition="before" #checkinOrCheckoutMenu="matMenu">
		<ng-container>
			<div class="flex items-center text-center justify-center mt-2">
				<mat-icon
					*ngIf="!isModeCheckIn()"
					class="w-5 mr-2"
					mat-icon-button
					[svgIcon]="'icon-stopwatch'"
				></mat-icon>
				<span *ngIf="!isModeCheckIn()" class="text-[#64748B] text-base font-medium">00:00:00</span>
			</div>
			<div class="flex flex-col justify-center text-[#64748B] font-medium text-base">
				<div class="flex mx-3">
					<span
						(click)="openModalcheckinOrCheckout()"
						*ngIf="isModeCheckIn()"
						class="underline cursor-pointer hover:text-[#019DE8]"
						>{{ 'MODULES.ADMIN.CHECK_IN_OR_CHECK_OUT.CHECK_OUT_UNIT' | transloco }}</span
					>
					<span
						(click)="openModalcheckinOrCheckout()"
						*ngIf="!isModeCheckIn()"
						class="underline cursor-pointer hover:text-[#019DE8]"
						>{{ 'MODULES.ADMIN.CHECK_IN_OR_CHECK_OUT.CHECK_IN_UNIT' | transloco }}</span
					>
				</div>
				<div class="flex mx-3 my-2" *ngIf="isModeCheckIn()">
					<mat-icon class="mr-2 text-[#019DE8] icon-size-5" svgIcon="feather:reset"></mat-icon>
					<span
						(click)="openCheckInResetDialog()"
						class="cursor-pointer hover:text-[#019DE8] flex items-center"
					>
						{{ 'MODULES.ADMIN.CHECK_IN_OR_CHECK_OUT.CHECK_IN_RESET' | transloco }}
					</span>
				</div>
				<div class="flex mx-3 my-2" *ngIf="isModeCheckIn()">
					<mat-icon class="w-5 mr-2 hover:text-[#019DE8]" mat-icon-button [svgIcon]="'icon-pause'"></mat-icon>
					<span
						(click)="openCheckInPauseDialog()"
						class="cursor-pointer hover:text-[#019DE8] flex items-center"
					>
						{{ 'MODULES.ADMIN.CHECK_IN_OR_CHECK_OUT.CHECK_IN_PAUSE' | transloco }}
					</span>
				</div>
			</div>
		</ng-container>
	</mat-menu>
</ng-container>

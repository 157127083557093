import { Injectable } from '@angular/core';
import { TranslateParams, TranslocoService } from '@ngneat/transloco';
import { lastValueFrom } from 'rxjs';
import ArchiveableBaseEntity from '../entity/archiveable-base.entity';
import { FuseConfirmationService } from './../../../../src/@fuse/services/confirmation/confirmation.service';
import { FuseConfirmationConfig } from './../../../../src/@fuse/services/confirmation/confirmation.types';
import { ApiService } from './api.service';

type ArchiveInput = {
	data: ArchiveableBaseEntity;
	archiveMessage: TranslateParams;
	unarchiveMessage: TranslateParams;
	service: ApiService<any>;
};

@Injectable()
export class ArchiveService {
	constructor(
		private _translate: TranslocoService,
		private _fuseConfirmationService: FuseConfirmationService,
	) {}

	async archive(input: ArchiveInput): Promise<boolean> {
		const payload = { archived: !input.data.archived };
		const confirmationConfig: FuseConfirmationConfig = {
			actions: {
				confirm: {
					label: this._translate.translate('ACTIONS.CONFIRM'),
					color: 'primary',
				},
			},
			icon: {
				show: true,
			},
		};

		if (input.data.archived) {
			confirmationConfig.icon.name = 'unarchive';
			confirmationConfig.title = this._translate.translate('ACTIONS.UNARCHIVE');
			confirmationConfig.message = this._translate.translate(input.unarchiveMessage);
		} else {
			confirmationConfig.icon.name = 'archive';
			confirmationConfig.title = this._translate.translate('ACTIONS.ARCHIVE');
			confirmationConfig.message = this._translate.translate(input.archiveMessage);
		}
		const confirmation = this._fuseConfirmationService.open(confirmationConfig);

		const result = await lastValueFrom(confirmation.afterClosed());
		if (result === 'confirmed') {
			let resp;
			if (input.data.archived) {
				resp = await lastValueFrom(input.service.unarchive(input.data.id));
			} else {
				resp = await lastValueFrom(input.service.archive(input.data.id));
			}
			return true;
		}
		return false;
	}
}

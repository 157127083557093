/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CheckInOut } from 'app/modules/cstools/check-in-out/entity/check-in-out.entity';
import { BehaviorSubject, Observable, catchError, of, take, tap } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AlertService } from '../../../../shared/services/alert.service';
import { CheckinCheckoutState } from '../enums/state.enum';
const API_CHECK_IN = `${environment.api}/geral/cadastro/usuario`;
const API_CHECK_IN_RESET = `${environment.api}/cstools/atendimento-reinicio`;
const API_SERVICE = `${environment.api}/cstools/atendimento`;

@Injectable({
	providedIn: 'root',
})
export class CheckinCheckoutService {
	_checkinState: BehaviorSubject<CheckinCheckoutState | null> = new BehaviorSubject(null);

	constructor(
		private http: HttpClient,
		private alert: AlertService,
	) {}

	get checkinState$(): Observable<CheckinCheckoutState> {
		return this._checkinState.asObservable();
	}

	set checkinState(value: CheckinCheckoutState) {
		this._checkinState.next(value);
	}

	checkInReset(motivo: string): Observable<string> {
		return this.http
			.post<string>(`${API_CHECK_IN_RESET}`, {
				motivo,
			})
			.pipe(
				take(1),
				tap(() => this.alert.showSuccess('Check-in reiniciado com sucesso!')),
				catchError((erro: any) => {
					this.alert.showError('Erro ao reiniciar o check-in', erro?.error?.message);
					return of(erro);
				}),
			);
	}

	searchLastContact(): Observable<any> {
		return this.http.get<any>(`${API_SERVICE}`).pipe(take(1));
	}

	postCheckOut(): Observable<any> {
		return this.http.post(`${API_SERVICE}`, null).pipe(take(1));
	}
	putCheckOut(data: CheckInOut): Observable<any> {
		return this.http.patch(`${API_SERVICE}/checkout`, data.toRequest()).pipe(
			take(1),
			tap(() => this.alert.showSuccess('Check-out realizado com sucesso!')),
			catchError((erro: any) => {
				this.alert.showError('Erro ao realizar o check-out', erro?.error?.message);
				return of(erro);
			}),
		);
	}

	checkIn(): Observable<any> {
		return this.http.patch(`${API_SERVICE}/checkin`, {}).pipe(
			take(1),
			tap(() => {
				this.checkinState = CheckinCheckoutState.CHECK_IN;
				this.alert.showSuccess('Check-in realizado com sucesso!');
			}),
			catchError((erro: any) => {
				this.alert.showError('Erro ao realizar o check-in', erro?.error?.message);
				return of(erro);
			}),
		);
	}
	checkInPause(): Observable<any> {
		return this.http.patch(`${API_SERVICE}/pendente`, {}).pipe(
			take(1),
			tap(() => {
				this.checkinState = CheckinCheckoutState.PAUSE;
				this.alert.showSuccess('Pausa realizado com sucesso!');
			}),
			catchError((erro: any) => {
				this.alert.showError('Erro ao realizar a pausa', erro?.error?.message);
				return of(erro);
			}),
		);
	}
}

import { Injectable } from '@angular/core';
import { HttpBaseService } from 'app/core/base/services/http-base.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export default class ResumoUnidadeService extends HttpBaseService {
	constructor() {
		super({
			url: 'cstools/analisador/resumo-unidade/estruturas-armazenagem',
		});
	}

	public getResumoUnidade<T>(): Observable<T> {
		const url = `${environment.api}/cstools/analisador/resumo-unidade/estruturas-armazenagem`;
		return this.request<T>('GET', url);
	}
}

import { APP_INITIALIZER, NgModule } from '@angular/core';
import { TRANSLOCO_LOADER, Translation, TranslocoModule, TranslocoService, provideTransloco } from '@ngneat/transloco';
import { TranslocoHttpLoader } from 'app/core/transloco/transloco.http-loader';
import { environment } from '../../../environments/environment';

@NgModule({
	exports: [TranslocoModule],
	providers: [
		provideTransloco({
			config: {
				availableLangs: [
					{
						id: 'en',
						label: 'English',
					},
					{
						id: 'tr',
						label: 'Turkish',
					},
					{
						id: 'pt',
						label: 'Português',
					},
				],
				defaultLang: 'pt',
				fallbackLang: 'pt',
				reRenderOnLangChange: true,
				prodMode: environment.production,
			},
			loader: TranslocoHttpLoader,
		}),
		{
			// Provide the default Transloco loader
			provide: TRANSLOCO_LOADER,
			useClass: TranslocoHttpLoader,
		},
		{
			// Preload the default language before the app starts to prevent empty/jumping content
			provide: APP_INITIALIZER,
			deps: [TranslocoService],
			useFactory:
				(translocoService: TranslocoService): any =>
				(): Promise<Translation> => {
					const defaultLang = translocoService.getDefaultLang();
					translocoService.setActiveLang(defaultLang);
					return translocoService.load(defaultLang).toPromise();
				},
			multi: true,
		},
	],
})
export class TranslocoCoreModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FuseHorizontalNavigationBasicItemComponent } from '@fuse/components/navigation/horizontal/components/basic/basic.component';
import { FuseHorizontalNavigationBranchItemComponent } from '@fuse/components/navigation/horizontal/components/branch/branch.component';
import { FuseHorizontalNavigationDividerItemComponent } from '@fuse/components/navigation/horizontal/components/divider/divider.component';
import { FuseHorizontalNavigationSpacerItemComponent } from '@fuse/components/navigation/horizontal/components/spacer/spacer.component';
import { FuseHorizontalNavigationComponent } from '@fuse/components/navigation/horizontal/horizontal.component';
import { FuseVerticalNavigationAsideItemComponent } from '@fuse/components/navigation/vertical/components/aside/aside.component';
import { FuseVerticalNavigationBasicItemComponent } from '@fuse/components/navigation/vertical/components/basic/basic.component';
import { FuseVerticalNavigationCollapsableItemComponent } from '@fuse/components/navigation/vertical/components/collapsable/collapsable.component';
import { FuseVerticalNavigationDividerItemComponent } from '@fuse/components/navigation/vertical/components/divider/divider.component';
import { FuseVerticalNavigationGroupItemComponent } from '@fuse/components/navigation/vertical/components/group/group.component';
import { FuseVerticalNavigationSpacerItemComponent } from '@fuse/components/navigation/vertical/components/spacer/spacer.component';
import { FuseVerticalNavigationComponent } from '@fuse/components/navigation/vertical/vertical.component';
import { FuseScrollbarModule } from '@fuse/directives/scrollbar/scrollbar.module';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		MatButtonModule,
		MatDividerModule,
		MatIconModule,
		MatMenuModule,
		MatTooltipModule,
		FuseScrollbarModule,
		FuseHorizontalNavigationBasicItemComponent,
		FuseHorizontalNavigationBranchItemComponent,
		FuseHorizontalNavigationDividerItemComponent,
		FuseHorizontalNavigationSpacerItemComponent,
		FuseHorizontalNavigationComponent,
		FuseVerticalNavigationAsideItemComponent,
		FuseVerticalNavigationBasicItemComponent,
		FuseVerticalNavigationCollapsableItemComponent,
		FuseVerticalNavigationDividerItemComponent,
		FuseVerticalNavigationGroupItemComponent,
		FuseVerticalNavigationSpacerItemComponent,
		FuseVerticalNavigationComponent,
	],
	exports: [
		FuseHorizontalNavigationComponent,
		FuseVerticalNavigationComponent,
		FuseHorizontalNavigationBasicItemComponent,
		FuseHorizontalNavigationBranchItemComponent,
		FuseHorizontalNavigationDividerItemComponent,
		FuseHorizontalNavigationSpacerItemComponent,
		FuseHorizontalNavigationComponent,
		FuseVerticalNavigationAsideItemComponent,
		FuseVerticalNavigationBasicItemComponent,
		FuseVerticalNavigationCollapsableItemComponent,
		FuseVerticalNavigationDividerItemComponent,
		FuseVerticalNavigationGroupItemComponent,
		FuseVerticalNavigationSpacerItemComponent,
		FuseVerticalNavigationComponent,
	],
})
export class FuseNavigationModule {}

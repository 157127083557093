import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslocoModule } from '@ngneat/transloco';
import { CdTimerModule } from 'angular-cd-timer';
import { SharedModule } from '../../../shared/shared.module';
import { CheckinCheckoutComponent } from './checkin-checkout.component';
import { CheckinResetDialogComponent } from './dialog/checkin-reset-dialog/checkin-reset-dialog.component';

@NgModule({
	declarations: [CheckinCheckoutComponent, CheckinResetDialogComponent],
	imports: [
		MatButtonModule,
		MatIconModule,
		MatMenuModule,
		SharedModule,
		TranslocoModule,
		CommonModule,
		CdTimerModule,
	],
	exports: [CheckinCheckoutComponent],
})
export class CheckinCheckoutModule {}

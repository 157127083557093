import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, catchError, of, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Navigation } from './navigation.types';

const MENU_CSTOOLS_TEMP = {
	default: [
		{
			id: 'storage',
			title: 'Armazenagem',
			classes: {
				title: 'text-[#019DE8] text-base',
			},
			subtitle: '',
			type: 'group',
			children: [
				{
					id: 'storage.analyzer',
					title: 'Analisador',
					type: 'collapsable',
					icon: 'icon-analyzer',
					link: '/analyzer',
					children: [
						{
							id: 'storage.analyzer-silos',
							icon: 'icon-silo',
							title: 'Silos',
							type: 'basic',
							link: '/analyzer/silos',
						},
						// {
						//     id: 'storage.analyzer-weatherstations',
						//     icon: 'icon-weather-station',
						//     title: 'Estações Meteorológicas',
						//     type: 'basic',
						//     link: '/analyzer/weatherstations',
						// },
						// {
						//     id: 'storage.analyzer-equipments',
						//     icon: 'icon-equipment-analyzer',
						//     title: 'Equipamentos',
						//     type: 'basic',
						//     link: '/analyzer/equipments',
						// },
						{
							id: 'storage.analyzer',
							title: 'Secadores',
							type: 'collapsable',
							icon: 'icon-dryer',
							children: [
								{
									id: 'storage.analyzer-secadores.php',
									icon: 'icon-dryer',
									title: 'Ceres 2.0',
									type: 'basic',
									link: '/analyzer/secadores',
								},
								{
									id: 'storage.analyzer-secadores.php',
									icon: 'icon-dryer',
									title: 'Ceres legado',
									type: 'basic',
									link: '/analyzer/ceres-legado',
								},
							],
						},
					],
				},
			],
		},
		{
			id: 'analyst',
			title: 'Atividades do Analista',
			classes: {
				title: 'text-[#019DE8] text-base',
			},
			subtitle: '',
			type: 'group',
			children: [
				{
					id: 'analyst.general-information',
					title: 'Informações Gerais',
					type: 'basic',
					icon: 'heroicons_outline:information-circle',
					link: '/analyst/general-information',
				},
				{
					id: 'analyst.check-in-out',
					title: 'Check-in / Check-out',
					type: 'basic',
					icon: 'mat_outline:logout',
					link: '/check-in',
				},
				{
					id: 'analyst.resumo-periodico',
					title: 'Resumo Periódico',
					type: 'basic',
					icon: 'heroicons_outline:mail',
					link: '/analyst/automatic-email',
				},
			],
		},
		{
			id: 'register',
			title: 'Cadastros',
			classes: {
				title: 'text-[#019DE8] text-base',
			},
			subtitle: '',
			type: 'group',
			children: [
				{
					id: 'register.users',
					title: 'Usuários',
					type: 'basic',
					icon: 'icon-users',
					link: '/config/user',
				},
				{
					id: 'register.branch-group',
					title: 'Grupos de Unidades',
					type: 'basic',
					icon: 'icon-storage-unit',
					link: '/config/branch-group',
				},
			],
		},
	],
};

let MENU_BOTOOLS_TEMP = {
	default: [
		{
			id: 'customers-menu',
			title: 'Cadastros',
			classes: { title: 'text-[#019DE8] text-base' },
			subtitle: '',
			type: 'group',
			children: [
				{
					id: 'customers-menu.customer',
					title: 'Clientes',
					type: 'basic',
					icon: 'icon-customer',
					link: '/customer',
				},
				{ id: 'units-menu.unit', title: 'Unidades', type: 'basic', icon: 'icon-storage-unit', link: '/branch' },
				{
					id: 'units-menu.contract',
					title: 'Gestão de Contratos',
					type: 'basic',
					icon: 'icon-contract',
					link: '/contract',
				},
				{
					id: 'units-menu.install',
					title: 'Instalação',
					type: 'basic',
					icon: 'icon-contract',
					link: '/instalacao',
				},
			],
		},
		{
			id: 'equipments-menu',
			title: 'Equipamentos',
			classes: { title: 'text-[#019DE8] text-base' },
			subtitle: '',
			type: 'group',
			children: [
				{
					id: 'equipments-main.transceiver',
					title: 'Transceptores',
					type: 'basic',
					icon: 'heroicons_outline:wifi',
					link: '/transceiver',
				},
				{
					id: 'equipments-menu.main',
					title: 'Principais',
					type: 'basic',
					icon: 'icon-equipment',
					link: '/main-equipment-new',
				},
			],
		},
		{
			id: 'stations-menu',
			title: 'Estações Meteorológicas',
			classes: { title: 'text-[#019DE8] text-base' },
			subtitle: '',
			type: 'group',
			children: [
				{
					id: 'stations-menu.local-stations',
					title: 'Locais',
					type: 'basic',
					icon: 'icon-meteorology-local',
					link: '/local-weatherstation',
				},
				{
					id: 'stations-menu.local-remote',
					title: 'Remotas',
					type: 'basic',
					icon: 'icon-meteorology-remote',
					link: '/remote-weatherstation',
				},
				//! Menu comentado por enquanto pois não há regras definidas
				// {
				// 	id: 'stations-menu.neighboring-station',
				// 	title: 'Próximas',
				// 	type: 'basic',
				// 	icon: 'icon-meteorology-neighboring',
				// 	link: '/estacao-proxima',
				// },
				{
					id: 'stations-menu.priority order',
					title: 'Ordem de Priorização',
					type: 'basic',
					icon: 'icon-priority-order',
					link: '/estacao-ordem-consulta',
				},
			],
		},
		{
			id: 'produtos-complementares',
			title: 'Produtos Complementares',
			classes: { title: 'text-[#019DE8] text-base' },
			subtitle: '',
			type: 'group',
			children: [
				{
					id: 'produtos-complementares',
					title: 'Produtos Complementares',
					type: 'basic',
					icon: 'icon-warehouse2',
					link: '/produtos-complementares',
				},
			],
		},
		{
			id: 'structures',
			title: 'Estruturas de Armazenagem',
			classes: { title: 'text-[#019DE8] text-base' },
			subtitle: '',
			type: 'group',
			children: [
				{ id: 'structures-silos', title: 'Silos', type: 'basic', icon: 'icon-silos', link: '/silo' },
				{
					id: 'structures-warehouses',
					title: 'Armazéns',
					type: 'basic',
					icon: 'icon-warehouse2',
					link: '/armazem',
				},
				{
					id: 'structures-change-pendulum',
					title: 'Trocas de Pêndulos',
					type: 'basic',
					icon: 'icon-change-pendulum',
					link: '/pendulum-exchanges',
				},
			],
		},
		{
			id: 'dryers',
			title: 'Secadores',
			classes: { title: 'text-[#019DE8] text-base' },
			subtitle: '',
			type: 'group',
			children: [
				// { id: 'dryers.dryer', title: 'Secadores', type: 'basic', icon: 'icon-dryer', link: '/secador' },
				{
					id: 'dryers.dryer',
					title: 'Secadores',
					type: 'basic',
					icon: 'icon-dryer',
					link: '/secador',
				},
			],
		},

		{
			id: 'config',
			title: 'Configurações',
			classes: { title: 'text-[#019DE8] text-base' },
			subtitle: '',
			type: 'group',
			children: [
				{
					id: 'service-management',
					title: 'Gerenciamento de Serviços',
					type: 'basic',
					icon: 'icon-temperature',
					link: '/gerenciamento-servico',
				},
			],
		},
		// {
		// 	id: 'audit',
		// 	title: 'Auditoria',
		// 	classes: { title: 'text-[#019DE8] text-base' },
		// 	subtitle: '',
		// 	type: 'group',
		// 	children: [
		// 		{
		// 			id: 'audit-checklist',
		// 			title: 'Checklist da unidade',
		// 			type: 'basic',
		// 			icon: 'icon-checklist',
		// 			link: '/checklist',
		// 		},
		// 	],
		// },
		{
			id: 'others',
			title: 'Outros Cadastros',
			classes: { title: 'text-[#019DE8] text-base' },
			subtitle: '',
			type: 'group',
			children: [
				{ id: 'others-location', title: 'Localização', type: 'basic', icon: 'icon-country', link: '/location' },
				{
					id: 'others-manufacturer',
					title: 'Fabricantes',
					type: 'basic',
					icon: 'icon-manufacturer',
					link: '/manufacturer',
				},
			],
		},
	],
};

const API_MENUS_BOTOOLS = `${environment.api}/backoffice/menu`;
const API_MENUS_CSTOOLS = `${environment.api}/backoffice/menu`;
@Injectable({
	providedIn: 'root',
})
export class NavigationService {
	private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

	private _software: BehaviorSubject<string> = new BehaviorSubject<string>('');

	private navigationTemp: Navigation;

	constructor(private _httpClient: HttpClient) {}

	get navigation$(): Observable<Navigation> {
		return this._navigation.asObservable();
	}

	get software$(): Observable<string> {
		return this._software.asObservable();
	}

	get(app: string): Observable<Navigation> {
		this.navigationTemp = MENU_CSTOOLS_TEMP as Navigation;
		if (environment.local) {
			const menuToHide = ['customers-menu'];
			// const childrenToHide = ['structures-warehouses'];

			// MENU_BOTOOLS_TEMP.default.forEach(menu => {
			// 	menu.children = menu.children.filter(child => !childrenToHide.includes(child.id));
			// });
			MENU_BOTOOLS_TEMP.default = MENU_BOTOOLS_TEMP.default.filter(menu => !menuToHide.includes(menu.id));
			MENU_BOTOOLS_TEMP.default = MENU_BOTOOLS_TEMP.default.filter(menu => menu.id !== 'others');
		} else {
			const menuToHide = ['config'];
			MENU_BOTOOLS_TEMP.default = MENU_BOTOOLS_TEMP.default.filter(menu => !menuToHide.includes(menu.id));
		}
		let urlMenu = API_MENUS_BOTOOLS;
		if (app === 'cstools') {
			urlMenu = API_MENUS_CSTOOLS;
		}
		return this._httpClient.get<Navigation>(urlMenu).pipe(
			tap(navigation => {
				if (app === 'cstools') {
					// Temporário, remover quando tiver o back do menu do cstools
					this._navigation.next(this.navigationTemp);
				} else {
					this._navigation.next(MENU_BOTOOLS_TEMP as unknown as Navigation);
				}
			}),
			catchError(error => {
				return of(error);
			}),
		);
	}

	setCurrentSoftware(value: string): void {
		this._software.next(value);
	}
}

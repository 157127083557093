<div class="text-base font-normal text-[#535767] mt-2 w-full">
	<div class="w-full flex">
		<div class="sm:w-[425px] flex flex-col" [ngClass]="{ 'ml-4': boxStyle }">
			<ngx-file-drop
				dropZoneLabel="Drop file here"
				[showBrowseBtn]="true"
				browseBtnLabel="Browse files"
				multiple="true"
				useDragEnter="true"
				(onFileDrop)="dropped($event)"
			>
				<ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
					<button class="buttonSearchFile" type="button" (click)="openFileSelector()">
						{{ 'SHARED.FILE_UPLOAD.CHOOSE_FILE' | transloco }}
					</button>
					{{ 'SHARED.FILE_UPLOAD.DRAG_FILE_HERE' | transloco }}
				</ng-template>
			</ngx-file-drop>
			<p *ngIf="messageWarning !== ''" class="mt-1 text-sm">
				{{ messageWarning }}
			</p>
		</div>
		<div class="flex flex-col justify-evenly ml-8" *ngIf="boxStyle">
			<div class="flex -mt-2">
				<mat-icon class="flex mr-2 text-[#019DE8]" svgIcon="mat_solid:warning"></mat-icon>
				<p class="mt-1 text-[#019DE8]">
					{{ 'SHARED.FILE_UPLOAD.WARNING_IMAGE' | transloco }}
				</p>
			</div>
		</div>
	</div>
	<div class="upload-table mt-3">
		<ng-container *ngIf="!boxStyle && !noDetails">
			<table class="table">
				<tbody class="upload-name-style">
					<tr *ngFor="let item of listFiles(); let i = index">
						<td class="itensFile w-full md:w-[425px] hover:bg-slate-200">
							<div class="relative" [ngClass]="{ 'cursor-pointer': item.id }" (click)="fileClick(item)">
								<mat-icon
									class="icon-size-10 text-hint opacity-50"
									[svgIcon]="'heroicons_solid:document'"
								></mat-icon>
								<div
									class="absolute left-0 bottom-0 px-1 rounded text-[8px] font-semibold leading-5 text-white"
									[ngClass]="getClassByType(item)"
								>
									{{ item.type.toUpperCase() }}
								</div>
							</div>
							<div class="w-full">
								<span
									(click)="fileClick(item)"
									[ngClass]="{ 'cursor-pointer': item.id }"
									class="text-[#64748B] text-md font-semibold"
								>
									{{ item.name }}
								</span>
								<br />
								<span class="text-[#535767] text-sm font-normal">{{ formatSize(item.size) }}</span>
							</div>
							<mat-icon svgIcon="heroicons_outline:x" (click)="removeFile(item)" class="cursor-pointer">
							</mat-icon>
						</td>
					</tr>
				</tbody>
			</table>
		</ng-container>
	</div>
</div>
<ng-container *ngIf="boxStyle">
	<div class="flex w-full min-h-[224px] flex-wrap p-4">
		<div
			*ngFor="let item of listFiles(); let i = index"
			class="flex items-center flex-col justify-center w-[180px] h-[180px] mr-4 mb-4 bg-white rounded-2xl drop-shadow"
			(click)="returnFile(item)"
		>
			<div class="relative" [ngClass]="{ 'cursor-pointer': item.id }">
				<mat-icon class="icon-size-16 text-hint opacity-50" [svgIcon]="'heroicons_solid:document'"></mat-icon>
				<div
					class="absolute left-0 bottom-0 px-1 rounded text-[14px] font-semibold leading-5 text-white"
					[ngClass]="getClassByType(item)"
				>
					{{ item.type.toUpperCase() }}
				</div>
			</div>
			<div class="mt-2">
				<span
					[ngClass]="{ 'cursor-pointer': item.id }"
					class="text-[#64748B] text-md font-semibold mt-1 text-center"
				>
					{{ item.name }}
				</span>
			</div>
		</div>
	</div>
</ng-container>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SharedModule } from '../../../shared/shared.module';
import { HeaderSelectCustomerForm } from './forms/header-select-customer.form';
import { HeaderSelectCustomerComponent } from './header-select-customer.component';

@NgModule({
	declarations: [HeaderSelectCustomerComponent],
	imports: [
		MatButtonModule,
		MatDividerModule,
		MatIconModule,
		MatTooltipModule,
		MatMenuModule,
		SharedModule,
		TranslocoModule,
		NgxMatSelectSearchModule,
		MatSelectModule,
		ReactiveFormsModule,
		CommonModule,
	],
	exports: [HeaderSelectCustomerComponent],
	providers: [HeaderSelectCustomerForm],
})
export class HeaderSelectCustomerModule {}

import { TimeInterface } from 'angular-cd-timer';
import moment from 'moment';

export class DateParserHelper {
	public static parseToISO(date: Date): string {
		if (date === undefined || date === null) {
			return null;
		}
		return moment(date).toISOString();
	}

	public static subtractHours(date: Date, hours: number): Date {
		if (date === undefined || date === null) {
			return null;
		}
		return moment(date).subtract(hours, 'hours').toDate();
	}

	public static parseToStr(date: string): string {
		if (date === undefined || date === null) {
			return null;
		}
		return moment(date, moment.ISO_8601).format('DD/MM/yyyy');
	}

	public static parseDateTimeToStr(date: string): string {
		if (date === undefined) {
			return null;
		}
		return moment(date).utcOffset('+00:00').format('DD/MM/YYYY HH:mm:ss');
	}

	public static parseToTime(date: Date): string {
		if (date === undefined) {
			return null;
		}
		return moment(date, moment.ISO_8601).format('HH:mm');
	}

	public static parseToDate(dateStr: string, fmt: string = 'DD/MM/yyyy'): Date {
		if (dateStr === undefined) {
			return null;
		}
		return moment(dateStr, fmt).toDate();
	}

	public static parseToDateTime(dateStr: string, fmt: string = 'DD/MM/yyyy H:mm:ss'): Date {
		if (dateStr === undefined) {
			return null;
		}
		return moment(dateStr, fmt).toDate();
	}

	public static parseTimeStrToDate(dateStr: string, fmt: string = 'H:mm:ss'): Date {
		if (dateStr === undefined) {
			return null;
		}
		return moment(dateStr, fmt).toDate();
	}

	public static dateNowSecondsDiff(initial: Date): number {
		return moment().diff(moment(initial, 'YYYY-MM-DD HH:mm:ss'), 'seconds');
	}

	public static dateNowTimeDiff(initial: Date): string {
		const diffInSeconds = moment().diff(moment(initial, 'YYYY-MM-DD HH:mm:ss'), 'seconds');
		const duration = moment.duration(diffInSeconds, 'seconds');
		const formattedTime = moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
		return formattedTime;
	}

	public static parseTimerToStr(timer: TimeInterface): string {
		return `${
			timer.hours.toString().padStart(2, '0') +
			':' +
			timer.minutes.toString().padStart(2, '0') +
			':' +
			timer.seconds.toString().padStart(2, '0')
		}`;
	}
	public static calendarToISODate(obj: any, addDay?: boolean): string {
		let formattedDate: Date;
		if (obj.$D) {
			formattedDate = new Date(obj.$y, obj.$M, obj.$D, obj.$H, obj.$m, obj.$s);
		} else if (obj._d) {
			formattedDate = new Date(obj._d);
		} else {
			return '';
		}
		if (addDay) {
			formattedDate.setDate(formattedDate.getDate() + 1);
		}
		const year = formattedDate.getFullYear();
		const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
		const day = formattedDate.getDate().toString().padStart(2, '0');
		return `${year}-${month}-${day}`;
	}
}

import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { Navigation } from 'app/core/navigation/navigation.types';
import { HeaderSelectCustomerService } from 'app/layout/common/header-select-customer/service/header-select-customer.service';
import { Subject, catchError, of, take, takeUntil } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Component({
	selector: 'classic-layout',
	templateUrl: './classic.component.html',
	styleUrls: ['./classic.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ClassicLayoutComponent implements OnInit, OnDestroy {
	isScreenSmall: boolean;
	isScreenMobile: boolean;
	isCSTools: boolean;
	navigation: Navigation;
	devEnviromentMode = false;
	hmlEnviromentMode = false;
	menuLoaded = false;
	appName = 'Backoffice Tools';
	navigationAppearance: 'default' | 'dense' = 'dense';
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	isLocal: boolean = false;
	unidadeClienteNome: string;

	/**
	 * Constructor
	 */
	constructor(
		private _activatedRoute: ActivatedRoute,
		private _router: Router,
		private _navigationService: NavigationService,
		private _fuseMediaWatcherService: FuseMediaWatcherService,
		private _fuseNavigationService: FuseNavigationService,
		private _changeDetectorRef: ChangeDetectorRef,
		private headerSelectCustomerService: HeaderSelectCustomerService,
	) {}

	// -----------------------------------------------------------------------------------------------------
	// @ Accessors
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Getter for current year
	 */
	get currentYear(): number {
		return new Date().getFullYear();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		this.isLocal = environment.local;

		if (this.isLocal) {
			this.unidadeClienteNome = localStorage.getItem('unidadeClienteNome') || '';
		}

		// Subscribe to navigation data
		this._navigationService.navigation$
			.pipe(
				takeUntil(this._unsubscribeAll),
				catchError(error => {
					return of(error);
				}),
			)
			.subscribe((navigation: Navigation) => {
				this.navigation = navigation;
				this.menuLoaded = true;
			});
		this._navigationService.get(localStorage.getItem('appSelected')).pipe(take(1)).subscribe();

		this._navigationService.software$.pipe(takeUntil(this._unsubscribeAll)).subscribe((software: string) => {
			this.isCSTools = software === 'cstools';
			if (this.isCSTools) {
				this.appName = 'CS Tools';
			}
		});

		this.isCSTools = localStorage.getItem('appSelected') === 'cstools';
		if (localStorage.getItem('appSelected') === 'cstools') {
			this.appName = 'CS Tools';
		}
		this._changeDetectorRef.markForCheck();

		// Subscribe to media changes
		this._fuseMediaWatcherService.onMediaChange$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(({ matchingAliases }) => {
				// Check if the screen is small
				this.isScreenSmall = !matchingAliases.includes('md');
				this.isScreenMobile = !matchingAliases.includes('sm');

				// Change the navigation appearance
				this.navigationAppearance = this.isScreenSmall ? 'default' : 'dense';
			});

		this.devEnviromentMode = !environment.production && !environment.homolog;
		this.hmlEnviromentMode = environment.homolog;
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Toggle navigation
	 *
	 * @param name
	 */
	toggleNavigation(name: string): void {
		// Get the navigation
		const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

		if (navigation) {
			// Toggle the opened status
			navigation.toggle();
		}
	}
}

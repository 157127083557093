<div class="w-full mt-4 w-[100%] text-base font-normal text-[#535767]">
	<label class="font-semibold text-md">{{ 'SHARED.FILE_UPLOAD.SEND_FILES' | transloco }}</label>
</div>

<div class="w-full md:w-[425px] text-base font-normal text-[#535767]">
	<ngx-file-drop
		dropZoneLabel="Drop files here"
		[showBrowseBtn]="true"
		browseBtnLabel="Browse files"
		multiple="true"
		useDragEnter="true"
		(onFileDrop)="dropped($event)"
	>
		<ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
			<button class="buttonSearchFile" type="button" (click)="openFileSelector()">
				{{ 'SHARED.FILE_UPLOAD.CHOOSE_FILE' | transloco }}
			</button>
			{{ 'SHARED.FILE_UPLOAD.DRAG_FILE_HERE' | transloco }}
		</ng-template>
	</ngx-file-drop>
	<div class="upload-table mt-3">
		<table class="table">
			<tbody class="upload-name-style">
				<tr *ngFor="let item of files; let i = index">
					<td class="itensFile w-full md:w-[425px]">
						<p>
							<span class="text-[#64748B] text-md font-semibold">
								{{ item.name }}
							</span>
							<br />
							<span class="text-[#535767] text-sm font-normal">{{ bytesToMegabytes(item.size) }}</span>
						</p>
						<mat-icon svgIcon="heroicons_outline:x" (click)="removeFile(i)" class="cursor-pointer">
						</mat-icon>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

<div
	class="selctUnit pt-4 flex flex-row flex-auto items-start"
	*ngIf="filiais.length > 0"
	[ngClass]="{ 'cursor-not-allowed': checkIn }"
>
	<div *ngIf="!isSelectOpened" class="flex">
		<button
			mat-icon-button
			(click)="previousFirstFiliais()"
			[disabled]="previousFirstFilialBtn || checkIn"
			matTooltip="{{ 'COMMON.PREVIOUS_FIRST_UNIT' | transloco }}"
			[ngClass]="{ 'cursor-not-allowed': checkIn }"
		>
			<mat-icon svgIcon="heroicons_outline:chevron-double-left" class="icon-size-5 tooltip"> </mat-icon>
		</button>
		<button
			mat-icon-button
			(click)="previousFilial()"
			matTooltip="{{ 'COMMON.PREVIOUS_UNIT' | transloco }}"
			[disabled]="previousFilialBtn || checkIn"
			[ngClass]="{ 'cursor-not-allowed': checkIn }"
		>
			<mat-icon svgIcon="heroicons_outline:chevron-left" class="icon-size-5"> </mat-icon>
		</button>
	</div>
	<div class="flex max-h-18" matTooltip="{{ getTooltipContent() }}">
		<mat-form-field [floatLabel]="'always'" (click)="branchSelectClick(true)">
			<mat-select
				[formControl]="filialCtrl"
				placeholder="Filial"
				#singleSelect
				(closed)="branchSelectClick(false)"
				(selectionChange)="selectChangeOption($event)"
			>
				<mat-option>
					<ngx-mat-select-search
						[formControl]="filialFilterCtrl"
						placeholderLabel="Procurar..."
						noEntriesFoundLabel="Nenhuma filial encontrada..."
					>
					</ngx-mat-select-search>
				</mat-option>
				<mat-option *ngFor="let filial of filteredFiliais | async" [value]="filial">
					{{ filial.cliente.nome }}: ({{ filial.nome }}) {{ filial.cidade.nome }} -
					{{ filial.cidade.uf.sigla }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div *ngIf="!isSelectOpened" class="flex">
		<button
			mat-icon-button
			(click)="nextFilial()"
			matTooltip="{{ 'COMMON.NEXT_UNIT' | transloco }}"
			[disabled]="nextFilialBtn || checkIn"
			[ngClass]="{ 'cursor-not-allowed': checkIn }"
		>
			<mat-icon svgIcon="heroicons_outline:chevron-right" class="icon-size-5 tooltip"> </mat-icon>
		</button>
		<button
			mat-icon-button
			(click)="nextLastFiliais()"
			matTooltip="{{ 'COMMON.NEXT_LAST_UNIT' | transloco }}"
			[disabled]="nextLastFilialBtn || checkIn"
			[ngClass]="{ 'cursor-not-allowed': checkIn }"
		>
			<mat-icon svgIcon="heroicons_outline:chevron-double-right" class="icon-size-5"> </mat-icon>
		</button>
	</div>
</div>
